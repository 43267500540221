<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Invitation to Sunway Malls</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <span class="caption grey--text text--darken-1">
              <h4 class="mb-3">Hi {{ user.name }},</h4>
              <p>
                Provide password to create your user account. <br />Your username is: <b>{{ user.username }}</b>
              </p>
            </span>
            <v-text-field :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordShow ? 'text' : 'password'" class="input-group--focused" @click:append="passwordShow = !passwordShow" id="password" label="Password" :rules="[rules.required, rules.password]" v-model="user.password" name="password" prepend-icon="mdi-lock" hint="Min 8 chars. Lower, Upper, special and digit must be used."></v-text-field>
            <v-text-field id="password" label="Confirm Password" v-model="user.confirmPassword" name="confirmPassword" prepend-icon="mdi-lock" type="password"></v-text-field>

            <div class="m-4 red--text" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" :disabled="processing" @keyup.enter="setupPassword()" type="submit" @click="setupPassword()">{{ processing ? "Authenticating..." : "REGISTER ACCOUNT" }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="showLogin" @click="gotoLogin()">Go to Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import router from "../../router"
import axios from "axios"
import {config, utils} from "../utils"
import {rules} from "../utils"

@Component({
  components: {},
})
export default class Invite extends Vue {
  ssoApiUrl = config.VUE_APP_SSO_API
  rules = rules
  passwordShow: any = false
  user: any = {
    password: "",
    confirmPassword: "",
  }
  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  params
  showLogin

  constructor() {
    super()
  }

  created() {
    this.showLogin = false
    this.params = this.$route.params
    if (!this.params.key) {
      console.log("You have no key")
    } else {
      this.checkKey()
    }
  }

  async checkKey() {
    let result = await axios.post(`${this.ssoApiUrl}/api/admin/check-invite`, {
      key: this.params.key,
    })
    if (result && result.data && result.data.username) {
      this.user.email = result.data.email
      this.user.username = result.data.username
      this.user.name = result.data.name
    }
  }
  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }
  async setupPassword() {
    this.errorMessage = ""
    this.processing = true
    let result
    if (!this.user.password) {
      this.errorPass()
      this.errorMessage = "Please enter new password"
      this.processing = false
      return
    }

    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    let validPass = regex.test(this.user.password)
    console.log("validPass", validPass)
    if (!validPass) {
      utils.showSnackbarError("Password must have 8 characters, at least one lower, one upper letter, one symbol and one digit")
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/accept-invite`, {
        password: this.user.password.trim(),
        confirmPassword: this.user.confirmPassword.trim(),
        key: this.params.key,
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while updating new password")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
    this.processing = false
    if (result.data) {
      let data = result.data
      console.log("data", data)
      if (data.error == true) {
        this.errorPass()
        console.log("Error in processing setupPassword ")
        this.errorMessage = data.message
        this.showLogin = true
        return
      }
      utils.showSnackbarSuccess("Success. Redirecting to login page...")
      setTimeout(function () {
        router.push("/login")
      }, 1280)
    } else {
      console.log("Error in validating username. No data")
    }
  }
  gotoLogin() {
    router.push("/login")
  }
}
</script>
