<template>
  <span>
    <div v-if="editor" class="editor-tiptap-icons">
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{'is-active': editor.isActive('bold')}"
      >
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{'is-active': editor.isActive('italic')}"
      >
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
        :class="{'is-active': editor.isActive('strike')}"
      >
        <v-icon>mdi-format-strikethrough</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .toggleCode()
            .run()
        "
        :class="{'is-active': editor.isActive('code')}"
      >
        <v-icon>mdi-format-code</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .unsetAllMarks()
            .run()
        "
      >
        <v-icon>mdi-format-clear</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .clearNodes()
            .run()
        "
      >
        clear nodes
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .setParagraph()
            .run()
        "
        :class="{'is-active': editor.isActive('paragraph')}"
      >
        <v-icon>mdi-format-paragraph</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 1})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 1})}"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 2})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 2})}"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 3})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 3})}"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 4})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 4})}"
      >
        <v-icon>mdi-format-header-4</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 5})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 5})}"
      >
        <v-icon>mdi-format-header-5</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({level: 6})
            .run()
        "
        :class="{'is-active': editor.isActive('heading', {level: 6})}"
      >
        <v-icon>mdi-format-header-6</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
        :class="{'is-active': editor.isActive('bulletList')}"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        "
        :class="{'is-active': editor.isActive('orderedList')}"
      >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .toggleCodeBlock()
            .run()
        "
        :class="{'is-active': editor.isActive('codeBlock')}"
      >
        code block
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .toggleBlockquote()
            .run()
        "
        :class="{'is-active': editor.isActive('blockquote')}"
      >
        <v-icon>mdi-format-quote-close</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .setHorizontalRule()
            .run()
        "
      >
        <v-icon>mdi-format-quote-close</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        @click="
          editor
            .chain()
            .focus()
            .setHardBreak()
            .run()
        "
      >
        hard break
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .undo()
            .run()
        "
      >
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-btn
        @click="
          editor
            .chain()
            .focus()
            .redo()
            .run()
        "
      >
        <v-icon>mdi-redo</v-icon>
      </v-btn>
    </div>

    <editor-content class="mt-1" :editor="editor" @input="changeHandler" />
  </span>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Editor, EditorContent} from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"

@Component({
  components: {EditorContent},
})
export default class TipTap extends Vue {
  editor: any = null

  @Prop({type: String, default: ""})
  value

  state: any = {}
  data: any = {
    model: {},
  }

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  }
  beforeDestroy() {
    this.editor.destroy()
  }

  @Watch("value")
  changedValue(value) {
    const isSame = this.editor.getHTML() === value

    // JSON
    // const isSame = this.editor.getJSON().toString() === value.toString()

    if (isSame) {
      return
    }

    this.editor.commands.setContent(this.value, false)
  }
  onInsertEmoIcon(payload: string) {
    this.editor
      .chain()
      .focus()
      .insertContent(payload)
      .run()
  }
  onRichTextChanged(meta) {
    let images = meta.value
    Vue.set(this.data.model, "images", images)
  }
  changeHandler(value) {
    console.log("changeHandler", value)
    this.$emit("input", value)
  }
}
</script>
<style lang="scss">
.editor-tiptap-icons .v-btn {
  padding: 0px 6px !important;
  min-width: auto !important;
  border-radius: 0px;
  margin-right: 1px;
  box-shadow: 0px none;
}
.editor-tiptap-icons .v-btn.is-active {
  background-color: #ffcdd2 !important;
}
.ProseMirror {
  background-color: #f4f4f4;
  padding: 10px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  li p {
    margin-bottom: 3px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
