import Vue from "vue"
import Vuex from "vuex"
import {cacheSrv} from "./../libs/cache/cache"
import eventBus from "./../eventBus"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notifications: 0,
    serviceWorkerRegistration: null,
    lastLink: cacheSrv.cached("last-link") || 0,
    leftMenuItems: [],
    token: "",
    profile: {},
    cacheFilters: {},
  },
  mutations: {
    incNotifications(state, count) {
      console.log("state.notifications = state.notifications + 1", count)
      if (count > 0) {
        state.notifications = state.notifications + 1
      } else {
        state.notifications = 0
      }
    },
    setServiceWorkerRegistration(state, swr) {
      state.serviceWorkerRegistration = swr
    },
    updateLastLink(state, linkIndex) {
      state.lastLink = linkIndex
      cacheSrv.cache("last-link", linkIndex)
    },
    updateLeftMenu(state, items) {
      state.leftMenuItems = items
    },
    updateProfile(state, profile) {
      state.profile = profile
      eventBus.$emit("user-session-event", profile)
    },
    updateToken(state, token) {
      state.token = token
    },
    logout(state, value) {
      console.log("Store cleaned")
      state.token = ""
      state.profile = {}
      state.lastLink = 0
      state.leftMenuItems = []
    },
  },
  getters: {
    profile: (state) => {
      let profile = state.profile
      return profile
    },
    lastLink: (state) => {
      return state.lastLink
    },
    token: (state) => {
      return state.token
    },
    serviceWorkerRegistration: (state) => {
      return state.serviceWorkerRegistration
    },
  },
  actions: {
    incNotifications(context, val) {
      context.commit("incNotifications", val)
    },
    setServiceWorkerRegistration(context, val) {
      context.commit("setServiceWorkerRegistration", val)
    },
    updateLeftMenu(context, val) {
      context.commit("updateLeftMenu", val)
    },
    updateLastLink(context, val) {
      context.commit("updateLastLink", val)
    },
  },
  modules: {},
})
