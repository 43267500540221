<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="pa-5" style="opacity: 0.88">
          <div v-if="data.step == 'forgot-password'" class="title text-center">Start Password Reset</div>
          <div v-else-if="data.step == 'reset-password-done'" class="title text-center">Reset Password Instructions</div>
          <div v-else class="title text-center">Terarra User/Agent Login</div>

          <v-card-text>
            <v-window v-model="data.step">
              <v-window-item :value="'login'">
                <v-card-text>
                  <v-form aria-autocomplete="off" autocomplete="off" @submit.prevent="authenticate" v-on:submit.prevent>
                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Username" name="login" prepend-icon="mdi-account" v-model="user.username" type="text"></v-text-field>

                    <v-text-field id="password" label="Password" v-model="user.password" name="password" prepend-icon="mdi-lock" type="password" @keyup.enter="authenticate()"></v-text-field>
                  </v-form>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="'forgot-password'">
                <v-card-text>
                  <v-form aria-autocomplete="off" autocomplete="off" @submit.prevent="authenticate" v-on:submit.prevent>
                    <v-text-field label="Enter your username" :persistent-hint="true" hint="We will send email to address associated with this username" name="username" prepend-icon="mdi-account" v-model="user.username" type="text"></v-text-field>
                  </v-form>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="'reset-password-done'">
                <v-card-text>
                  <p>We have emailed instructions on how to reset password associated with this account.</p>
                  <p>If you do not receive email in next few minutes, please check spam folder or contact us for assistance.</p>
                </v-card-text>
              </v-window-item>
            </v-window>

            <div class="m-4 red--text" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-if="data.step == 'login'" :disabled="processing" @keyup.enter="authenticate()" type="submit" @click="authenticate()">{{ processing ? "Authenticating..." : "LOGIN" }}</v-btn>

            <v-btn color="primary" v-if="data.step == 'forgot-password'" :disabled="processing" @keyup.enter="resetPassword()" type="submit" @click="resetPassword()">{{ processing ? "Processing..." : "Reset Password" }}</v-btn>
            <v-btn color="primary" v-if="data.step == 'reset-password-done'" :disabled="processing" @click="data.step = 'login'">Go to Login</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="data.step = 'forgot-password'" v-if="data.step == 'login'">Forgot Password?</v-btn>
            <v-btn @click="data.step = 'login'" v-if="data.step != 'login'">Back</v-btn>
          </v-card-actions>
          <v-card-actions >
            New to Terrara?
            <v-btn to="/register" color="secondary" type="button" text>Join </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import {cacheSrv} from "../cache/cache"
import router from "./../../router"
import {loginService} from "./login-service"
import {profileService} from "./profile-service"
import {userService} from "./user-service"
import {config, utils} from "../utils"
import axios from "axios"

@Component({
  components: {},
})
export default class Login extends Vue {
  ssoApiUrl = config.VUE_APP_SSO_API
  homeUrl = config.VUE_APP_APP_URL

  user = {
    username: "",
    password: "",
  }
  data: any = {
    step: "login",
  }

  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  constructor() {
    super()
  }

  async authenticate() {
    //Clean up stored profile
    cacheSrv.remove("profile")

    console.log("authenticate")
    this.errorMessage = ""
    this.processing = true
    let result

    if (!this.user.password) {
      this.errorPass()
      this.errorMessage = "Please enter username and password"
      this.processing = false
      return
    }

    try {
      result = await loginService.login({username: this.user.username.trim(), password: this.user.password.trim()})
      this.processing = false
      if (result) {
        let data = result

        if (data.error) {
          this.errorPass()
          console.log("Error in validating number")
          this.errorMessage = data.message
          return
        }

        if (data["token"]) {
          this.errorMessage = ""
          await userService.initUser()
          await profileService.authorize()

          setTimeout(function () {
            router.push("/")
          }, 888)
        } else {
          console.log("no token")
          this.errorPass()
        }
      } else {
        console.log("Error in validating username. No data")
      }
    } catch (e) {
      console.log("error logging in", e)
      this.errorPass()
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }

  async resetPassword() {
    this.errorMessage = ""
    this.processing = true
    let result
    if (!this.user.username) {
      this.errorPass()
      this.errorMessage = "Please enter username"
      this.processing = false
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/request-reset-password`, {
        username: this.user.username.trim(),
        targeturl: this.homeUrl,
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while reseting pass")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }

    this.processing = false
    if (result.data) {
      let data = result.data
      console.log("data", data)

      if (data.error == true) {
        this.errorPass()
        console.log("Error in resettng pass")
        this.errorMessage = data.message
        return
      }

      this.errorMessage = ""
      this.data.step = "reset-password-done"
    } else {
      console.log("Error in validating username. No data")
    }
  }
}
</script>
