<template>
  <div class="pl-14">
    <slot name="main"> </slot>
    
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator"
import store from "../store"

@Component({
  components: {},
})
export default class LeftMenuItems extends Vue {
  items: any = store.state.leftMenuItems

  create() {
    console.log("Left nav created", this.items)
  }
  goto(link) {
    this.$router.push(link.route).catch(() => {
      console.log("no")
    })
  }
}
</script>
