import {baseService} from "./../../libs/api-services/base-service"

class SettingsService {
  URL_PREFIX = "settings/"

  async getSub() {
    return await baseService.get(`${this.URL_PREFIX}push-subscription`, {})
  }
  async subscription(payload: any) {
    return await baseService.post(`${this.URL_PREFIX}push-subscription`, payload)
  }
  async testPush(payload: any) {
    return await baseService.post(`${this.URL_PREFIX}push-subscription/test`, payload)
  }
}

export const settingsService = new SettingsService()
