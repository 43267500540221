<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Logout Confirmation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>Do you wish to logout? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="white--text" type="button" @click="logout()">{{ processing ? "Logging out..." : "YES, LOG ME OUT" }}</v-btn>
            <v-btn color="secondary" @click="cancel()">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import store from "./../../store"
import router from "./../../router"
import {cacheSrv} from "./../cache/cache"

@Component({
  components: {},
})
export default class Logout extends Vue {
  loggedout = false
  processing = false

  constructor() {
    super()
  }

  created() {}

  login() {
    this.redirectToLogin()
  }

  cancel() {
    router.push("dashboard")
  }

  logout() {
    this.processing = true
    console.log("Local storage cleanup")
    cacheSrv.removeAll()
    store.commit(`logout`)
    this.redirectToLogin()
    this.processing = false
    this.loggedout = true
  }

  redirectToLogin() {
    setTimeout(() => {
      document.location.reload()
    }, 10)
  }
}
</script>
