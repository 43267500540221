import {config} from "./../utils"
import BaseApi from "./base-api"
import {appConfig} from "./../config"

export default class SSOPublicApi extends BaseApi {
  apiUrl = config.VUE_APP_SSO_API

  constructor() {
    super()
  }

  getApiUrl() {
    return this.apiUrl
  }
}
