import {cacheSrv} from "./../cache/cache"
import SSOPublicApi from "../api-services/server-sso-public-api"
import store from "@/store"

class LoginService extends SSOPublicApi {
  async login(payload) {
    console.log("Login")
    let result = await this.post("/api/user/login", payload)
    if (result && result.error) {
      console.log("Error login", result.message)
    } else {
      let data = result || {}
      if (data["token"]) {
        console.log("Token saved")
        store.commit(`updateToken`, data["token"])
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360)
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360, "SSO")
      } else {
        console.log("Failed to obtain sso token")
      }
    }
    return result
  }

  async loginMS(msToken) {
    console.log("Login MS", msToken)
    let result = await this.post("/api/ms-auth/login", {}, {authorization: "Bearer " + msToken})
    if (result && result.error) {
      console.log("Error login", result.message)
    } else {
      let data = result || {}
      if (data["token"]) {
        console.log("Token saved")
        store.commit(`updateToken`, data["token"])
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360)
        cacheSrv.cacheEx(this.CACHE_KEY_SSO_TOKEN, data["token"], 60 * 60 * 24 * 360, "SSO")
      } else {
        console.log("Failed to obtain sso token")
      }
    }
    return result
  }
}

export const loginService = new LoginService()
