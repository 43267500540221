import ServerOrganiseApi from "./../../libs/api-services/server-api"
class PreferenceService extends ServerOrganiseApi{
  URL_PREFIX = "/v1/secure/preferences/"
  
  async saveEmailSignature(payload: any) {
    return await this.post(`${this.URL_PREFIX}email-signature`, payload)
  }

  async getPreference() {
    return await this.get(`${this.URL_PREFIX}`)
  }
}
export const preferenceService = new PreferenceService()
