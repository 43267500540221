<template>
  <v-app id="simple" style="background-size: cover" color="primary" :style="{backgroundImage: 'url(\'' + require('@/assets/bg01.jpg') + '\')'}">
    <v-app-bar app clipped-right dark color="primary">
      <v-container fluid class="py-0 fill-height">
        <div class="d-none d-md-block">
          <v-btn to="/" text color="white"> Search </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn to="/register" text color="white">Join </v-btn>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
      <v-snackbar v-model="data.snackbar.visible" :color="data.snackbar.color">
        {{ data.snackbar.text }}
        <template v-slot:action="{attrs}">
          <v-btn :color="data.snackbar.buttonColor" text v-bind="attrs" @click="data.snackbar.visible = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import EventBus from "./../eventBus"

@Component({})
export default class SimpleLayout extends Vue {
  data: any = {
    snackbar: {
      visible: false,
      text: "",
      buttonColor: "white",
    },
  }

  constructor() {
    super()
  }

  created() {
    EventBus.$on("display-snackbar", this.displaySnackbar)
  }

  beforeDestroy() {
    EventBus.$off("display-snackbar", this.displaySnackbar)
  }

  displaySnackbar(payload: any) {
    this.data.snackbar.visible = false // first close any open snackbar
    this.data.snackbar.text = payload.text
    this.data.snackbar.visible = true

    switch (payload.type) {
      case "success": {
        this.data.snackbar.color = "success"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "error": {
        this.data.snackbar.color = "error"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "warning": {
        this.data.snackbar.color = "warning"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "info": {
        this.data.snackbar.color = "info"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      default: {
        this.data.snackbar.color = "primary"
        this.data.snackbar.buttonColor = "white"
        break
      }
    }
  }
}
</script>
