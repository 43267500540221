import BaseApi from "../api-services/base-api"

export default class BaseDomainService extends BaseApi {
  URL_PREFIX = ""

  constructor() {
    super()
  }

  validate() {
    if (this.URL_PREFIX == "") {
      console.log("Error. Missing URL PREFIX")
      return false
    }
    return true
  }

  async find(filter: any, options: any = {}) {
    if (!this.validate()) {
      return false
    }
    return await this.get(`${this.URL_PREFIX}find`, filter, options)
  }

  async findUnique(filter: any) {
    if (!this.validate()) {
      return false
    }
    return await this.get(`${this.URL_PREFIX}find-unique`, filter)
  }

  async getItems(payload: any, pagination: any) {
    if (!this.validate()) {
      return false
    }
    return await this.get(`${this.URL_PREFIX}`, payload, pagination)
  }
  async deleteItem(itemId: string) {
    if (!this.validate()) {
      return false
    }
    return await this.delete(`${this.URL_PREFIX}${itemId}`, {})
  }

  async getItem(itemId: string) {
    if (!this.validate()) {
      return false
    }
    return await this.get(`${this.URL_PREFIX}${itemId}`, {})
  }

  async updateItem(payload: any) {
    if (!this.validate()) {
      return false
    }
    return await this.post(`${this.URL_PREFIX}${payload._id}`, payload)
  }

  async createItem(payload: any) {
    if (!this.validate()) {
      return false
    }
    return await this.post(`${this.URL_PREFIX}`, payload)
  }

  async restore(payload: any) {
    if (!this.validate()) {
      return false
    }
    return await this.post(`${this.URL_PREFIX}${payload._id}/restore`, payload)
  }
}
