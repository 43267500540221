import ServerApi from "./../../libs/api-services/server-public-api"

class SearchService extends ServerApi {
  URL_PREFIX = "/v1/public/maps/"

  async loadObjectsForPrcID(PRCID: string) {
    return await this.get(`/v1/public/maps/prcid/${PRCID}/objects`)
  }

  async search(searchQuery, searchQueryObject) {
    console.log("URL", `search?searchQuery=${searchQuery}&${searchQueryObject}`)
    return await this.get(`/v1/public/maps/search?searchQuery=${searchQuery}&${searchQueryObject}`)
  }
}

export const searchService = new SearchService()
