export const appConfig = {
  VUE_APP_TITLE: appConfiguration.VUE_APP_TITLE || process.env.VUE_APP_TITLE,
  VUE_APP_SSO_API: appConfiguration.VUE_APP_SSO_API || process.env.VUE_APP_SSO_API,
  VUE_APP_SERVER_API: appConfiguration.VUE_APP_SERVER_API || process.env.VUE_APP_SERVER_API,
  VUE_APP_SERVER_IMPORTER_API: appConfiguration.VUE_APP_SERVER_IMPORTER_API || process.env.VUE_APP_SERVER_IMPORTER_API,
  VUE_APP_SERVER_STAFF_API: appConfiguration.VUE_APP_SERVER_STAFF_API || process.env.VUE_APP_SERVER_STAFF_API,

  VUE_APP_ANALYTICS_URL: appConfiguration.VUE_APP_ANALYTICS_URL || process.env.VUE_APP_ANALYTICS_URL,
  VUE_APP_PUBLIC_IMAGE_URL: appConfiguration.VUE_APP_PUBLIC_IMAGE_URL || process.env.VUE_APP_PUBLIC_IMAGE_URL,
  VUE_APP_SERVER_PATH: appConfiguration.VUE_APP_SERVER_PATH || process.env.VUE_APP_SERVER_PATH,
  VUE_APP_SOCKET_SERVER: appConfiguration.VUE_APP_SOCKET_SERVER || process.env.VUE_APP_SOCKET_SERVER,

  VUE_APP_COMPANY: appConfiguration.VUE_APP_COMPANY || process.env.VUE_APP_COMPANY,

  VUE_APP_SOCKET_PATH: appConfiguration.VUE_APP_SOCKET_PATH || process.env.VUE_APP_SOCKET_PATH,
  VUE_APP_SYSTEM_BAR_COLOR: appConfiguration.VUE_APP_SYSTEM_BAR_COLOR || process.env.VUE_APP_SYSTEM_BAR_COLOR,

  VUE_APP_APP_URL: appConfiguration.VUE_APP_APP_URL || process.env.VUE_APP_APP_URL,
  VUE_APP_MAPBOX_STYLE: appConfiguration.VUE_APP_MAPBOX_STYLE || process.env.VUE_APP_MAPBOX_STYLE,
}