import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2199A5",//#0D5775
        secondary: "#F50057",
        accent: "#176b73",
        error: "#f44336",
        info: "#2196f3",
        success: "#4caf50",
        warning: "#ff9800",
      }
    },
  },
})
