<template>
  <v-autocomplete autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" ref="searchBoxMain" style="" dense flat dark hide-details rounded solo-inverted prepend-inner-icon="mdi-magnify" label="" class="d-lg-flex grey lighten-1" color="primary" v-model="data.model" :items="data.items" :loading="data.isLoading" :search-input.sync="data.search" item-text="name" item-value="_id" placeholder="Search eveything" hide-no-data clearable @focus="onFocus" @change="onChange" :no-filter="true" return-object>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Search
          <strong>Tickets</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-content>
        <v-list-item-title v-html="item.name"></v-list-item-title>
        <v-list-item-subtitle>{{ item.no }} &middot; {{ item.s }} &middot; {{ item.stg }} </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-ticket</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>
<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator"
import {debounce} from "../debounce"
import eventBus from "./../../eventBus"
import {searchService} from "./search-service"

@Component({
  components: {},
})
export default class Search extends Vue {
  data: any = {
    items: [],
    isLoading: false,
    model: null,
    search: null,
  }
  onChange(val) {}
  onFocus() {}
  @Watch("data.search")
  find(val) {
    if (val == null) {
      return
    }
    debounce.delay(() => {
      this.loadItems()
    }, 450)
  }
  @Watch("data.model")
  model(item) {
    console.log("Changed model", item)
    if (item && item._id) {
      this.$router.push({name: "TicketView", params: {id: item._id}}).catch(() => {})
      eventBus.$emit("reloadTicketForId", item._id)
    }
    this.data.search = ""
    this.data.items = []
    try {
      ;(this.$refs["searchBoxMain"] as HTMLFormElement).blur()
    } catch (error) {}
  }
  async loadItems() {
    console.log("loadItems")
    if (this.data.isLoading) {
      return
    }
    if (this.data.search == null) {
      return
    }
    this.data.isLoading = true
    let payload = {q: this.data.search}
    try {
      let res = await searchService.find(payload)
      if (res.error == true) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Failed to load items. Please check your internet connection and try again"})
        this.data.isLoading = false
        return
      }
      this.data.items = res.hits.map((i) => {
        let t = i.highlight.text
        let no = i._source.ticketNoKeyword
        return {
          name: t ? t.join(", ") : no,
          _id: i._source.primaryId,
          no: i._source.ticketNoKeyword,
          s: i._source.source,
          stg: i._source.stage,
        }
      })
      this.data.isLoading = false
    } catch (error) {
      console.log(error)
      this.data.isLoading = false
    }
  }
}
</script>
