class MainInit {
  constructor() {
  }

  async initialise() {
    return true
  }
}

export const mainInit = new MainInit()
