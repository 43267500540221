<template>
  <div class="dash ma-2">
    <h2>Employee Directory Dashboard</h2>
    <p class="mt-5">Welcome {{ profile.name }}</p>
    <div class="row mt-5" v-if="profile.roles">
      <div class="col-6 col-md-6">
        <v-card flat class="d-flex flex-column text-center pa-4" color="primary" dark>
          <h3>HR Recruitment Portal Access</h3>
          <br />
          <p>Click on the button below to access HR Recruitment Portal </p>
          <v-btn depressed rounded class="d-flex white black--text" dark href="https://careers.sunwaymalls.com/recruit-ui/">
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-btn>
        </v-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <v-card>
          <v-card-title><small>NEW EMPLOYEES</small></v-card-title>
          <v-list two-line>
            <template v-for="item in data.newEmployees">
              <v-list-item :key="item.name">
                <v-list-item-avatar>
                  <v-img :src="item.photo" v-if="item.photo"></v-img>
                  <v-avatar v-else color="primary white--text" size="40">
                    <div class="">{{ (item.name || item.username) | initials }}</div>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.department ? item.department.name : "" }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import {debounce} from "../../libs/debounce"
import {dashboardService} from "./dashboard-service"
import {utils} from "@/libs/utils"
import {userService} from "@/libs/user/user-service"

@Component({
  components: {},
})
export default class Summary extends Vue {
  data: any = {
    newEmployees: [],
  }
  pageSetting: any = {}
  profile: any = {}

  async created() {
    this.profile = userService.getProfile()
    this.init()
  }
  async init() {
    debounce.delay(async () => {
      let res = await dashboardService.newEmployees()
      this.data.newEmployees =
        res.map((i) => {
          i.color = this.getStringToHslColor(i.name || i.username)
          return i
        }) || []
    }, 450)
  }

  getStringToHslColor(initials) {
    let e = utils.stringToHslColor(initials)
    console.log("getStringToHslColor", e)
    return e
  }
}
</script>
