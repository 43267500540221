<template>
  <v-app id="inner" :class="fullView ? 'full-view' : ''">
    <v-system-bar app :color="systemBarColor" height="20">
      <v-spacer></v-spacer>
      <small class="pointer ml-2 hidden-sm-and-down" @click="globalVullView">FULL-VIEW</small>

      <small class="ml-3 hidden-sm-and-down">MAPS v1.0</small>
    </v-system-bar>

    <v-app-bar app v-if="!fullView" clipped-left dark aflat height="59" class="no-print elevation-5 primary darken-3" style="opacity: 0.7">
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="mainMenu = !mainMenu"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu :nudge-width="100" offset-y transition="slide-x-transition" bottom right>
        <template v-slot:activator="{on, attrs}">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar color="primary darken-4" size="40">
              <div class="">{{ (data.profile.name || data.profile.username) | initials }}</div>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar class="" color="primary darken-4" size="40">
                  <div class="white--text">{{ (data.profile.name || data.profile.username) | initials }}</div>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ data.profile.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.profile.username }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-bottom-navigation>
            <v-btn href="https://careers.sunwaymalls.com/directory-ui/">
              <span>HR Directory</span>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-btn>
            <v-btn href="https://careers.sunwaymalls.com/recruit-ui/">
              <span>HR Recruit</span>
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-btn>
            <!-- <v-btn href="https://careers.sunwaymalls.com/safety-ui/">
              <span>HR Safety</span>
              <v-icon>mdi-seatbelt</v-icon>
            </v-btn> -->
          </v-bottom-navigation>

          <v-divider></v-divider>

          <v-list subheader two-line>
            <v-list-item to="logout" link>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-switch v-model="$vuetify.theme.dark" class="d-none pt-4 ml-4" label="Dark"></v-switch>
    </v-app-bar>

    <v-navigation-drawer v-if="!fullView" width="250" v-model="mainMenu" app max-width="280" class="no-print" style="opacity: 0.98">
      <v-navigation-drawer dark permanent :mini-variant="mini" mini-variant-width="56" v-model="miniMenu" absolute color="primary darken-3" style="visibility: visible !important; transform: translateX(0%) !important">
        <v-list-item @click="mini = !mini">
          <v-icon>mdi-menu</v-icon>
          <v-list-item-title class="ml-2 body-2"> HR DIRECTORY </v-list-item-title>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item :title="item.text" @click="filterLinks(item, index)" v-for="(item, index) in headings" :key="item.route + '-' + index" class="mb-3" :class="activeMini == index ? 'white darken-4 black--text' : ''">
            <v-list-item-icon>
              <v-icon dark :class="activeMini == index ? 'primary--text' : ''">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-sheet color="grey lighten-3" height="59" width="100%" class="pl-16 pt-2">
        <div id="clock" class="mr-2" style="">
          <div class="date my-1" style="line-height: 1; font-size: 11px">{{ clock.date | FormatDateLong }}</div>
          <div class="time my-1 secondary--text" style="line-height: 1; font-size: 22px">{{ clock.time | FormatTime }}</div>
        </div>
      </v-sheet>

      <LeftMenuItems>
        <template v-slot:main>
          <div class="black--text text-left pl-3 pb-2 mb-1 pt-3 text-uppercase body-2 border-bottom" v-if="headings && activeMini >= 0">
            <v-icon class="black--text" small>{{ headings[activeMini].icon }} </v-icon> {{ headings[activeMini].text }}
          </div>
          <v-list rounded dense>
            <v-list-item v-for="(item, index) in mainLinks" :to="item.route" :key="item.text + '--' + index" link @click="goto(item)">
              <v-list-item-content>
                <v-list-item-title class="body-2 secondary--text"> {{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </LeftMenuItems>
    </v-navigation-drawer>
    <v-main style="background-color: #effcef">
      <v-container fluid class="pa-2">
        <router-view></router-view>
      </v-container>
      <v-snackbar v-model="data.snackbar.visible" :color="data.snackbar.color">
        {{ data.snackbar.text }}
        <template v-slot:action="{attrs}">
          <v-btn :color="data.snackbar.buttonColor" text v-bind="attrs" @click="data.snackbar.visible = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import router from "./../router"
import eventBus from "./../eventBus"
import Search from "./../libs/search/Search.vue"
import store from "./../store"
import LeftMenuItems from "./LeftMenuItems.vue"
import {userService} from "../libs/user/user-service"
import {appConfig} from "../libs/config"

@Component({
  components: {Search, LeftMenuItems},
})
export default class AppDotty extends Vue {
  systemBarColor = appConfig.VUE_APP_SYSTEM_BAR_COLOR || "primary"
  company: any = appConfig.VUE_APP_COMPANY || ""
  mini = true
  mainMenu: any = null // indicates whether the LHS menu should be
  miniMenu = null // indicates whether the LHS menu should be
  rightMenu = false // indicates whether the LHS menu should be
  items = []

  headings: any = []
  activeMini = 0
  mainLinks: any = []

  data: any = {
    snackbar: {
      visible: false,
      text: "",
      buttonColor: "white",
    },
    profile: {},
    showGlobalSearch: true,
  }

  clock: any = {
    time: "",
    date: "",
  }

  timerID
  week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

  fullView = false
  globalVullView() {
    this.fullView = !this.fullView
  }
  updateTime() {
    var cd = new Date()
    this.clock.time = this.zeroPadding(cd.getHours(), 2) + ":" + this.zeroPadding(cd.getMinutes(), 2) + ":" + this.zeroPadding(cd.getSeconds(), 2)
    this.clock.date = this.zeroPadding(cd.getFullYear(), 4) + "-" + this.zeroPadding(cd.getMonth() + 1, 2) + "-" + this.zeroPadding(cd.getDate(), 2)
  }

  zeroPadding(num, digit) {
    var zero = ""
    for (var i = 0; i < digit; i++) {
      zero += "0"
    }
    return (zero + num).slice(-digit)
  }

  created() {
    this.renderMenuItems()
    eventBus.$on("display-snackbar", this.displaySnackbar)
    this.data.profile = store.getters.profile
    this.timerID = setInterval(this.updateTime, 1000)
    this.updateTime()
    this.presetLastLink()
  }

  renderMenuItems() {
    let userProfile = userService.getProfile()
    let settings = [
      {icon: "mdi-cog", text: "My Settings", route: {name: "settings"}},
      {icon: "mdi-logout", text: "Logout", route: {name: "logout"}},
    ]
    let configuration = [
      {role: "super,mapadmin:20", icon: "mdi-comment-multiple", text: "Cantons", route: "/cantons"},
    ]
    let items: any = [
      {
        text: "Dashboard",
        icon: "mdi-shape",
        items: [{icon: "mdi-view-dashboard", text: "Summary", route: "/"}],
      },
      {
        role: "super,mapuser:10",
        text: "Directory",
        icon: "mdi-account-group",
        items: [
          {
            role: "super,mapuser:10",
            icon: "mdi-comment-multiple",
            text: "Employees",
            route: "/employees",
          },
        ],
      },
      {
        role: "super,mapuser:20",
        text: "Configuration",
        icon: "mdi-cog",
        items: configuration,
      },
      {
        text: "Settings",
        icon: "mdi-account",
        items: settings,
      },
    ]

    let itms = items.filter((i) => {
      if (i.role) {
        let roles = i.role.split(",").map((k) => {
          return k.trim().toUpperCase()
        })
        if (!userService.hasAccess(roles)) {
          return false
        }
      }
      return true
    })
    itms.forEach((i) => {
      let subs = []
      if (i.items) {
        subs = i.items.filter((s) => {
          if (s.role) {
            let roles = s.role.split(",").map((k) => {
              return k.trim().toUpperCase()
            })
            if (!userService.hasAccess(roles)) {
              return false
            }
          }
          return true
        })
      }
      i.items = subs
    })
    Vue.set(this, "items", itms)

    this.headings = this.items.filter((i: any) => {
      return i.items
    })
  }
  presetLastLink() {
    let lastLink = store.getters.lastLink
    if (!lastLink) {
      this.filterLinks(this.headings[0], 0, false)
    } else {
      if (this.headings[lastLink]) {
        this.mainLinks = this.headings[lastLink].items
        this.activeMini = lastLink
      }
    }
  }
  filterLinks(link, index, gotoLink = true) {
    this.mainLinks = link.items
    store.commit(`updateLastLink`, index)
    if (this.mainLinks.length > 0 && gotoLink) {
      this.goto(this.mainLinks[0])
    }
    this.activeMini = index
  }
  goto(link) {
    this.$router.push(link.route).catch(() => {
      console.log("no")
    })
  }

  beforeDestroy() {
    clearInterval(this.timerID)
    eventBus.$off("display-snackbar", this.displaySnackbar)
  }

  accountSettings() {
    router.push({name: "settings"}).catch((err) => {
      console.log("error routing", err)
    })
  }

  displaySnackbar(payload: any) {
    this.data.snackbar.visible = false // first close any open snackbar
    this.data.snackbar.text = payload.text
    this.data.snackbar.visible = true

    switch (payload.type) {
      case "success": {
        this.data.snackbar.color = "success"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "error": {
        this.data.snackbar.color = "error"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "warning": {
        this.data.snackbar.color = "warning"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "info": {
        this.data.snackbar.color = "info"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      default: {
        this.data.snackbar.color = "primary"
        //this.data.snackbar.buttonColor = "white";
        break
      }
    }
  }
}
</script>
