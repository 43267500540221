import {sateliteControl} from "./satelite-control"
import {baseStyleControl} from "./base-control"

class AddOns {
  map: any

  constructor() {}

  init(map) {
    console.log("AddOns init called")
    this.map = map

    this.map.addControl(sateliteControl)
    this.map.addControl(baseStyleControl)
  }
}

export const addons = new AddOns()
