import {config} from "../../libs/utils"

class BaseStyleControl {
  map: any
  private _container: HTMLDivElement
  mapboxStyle = config.VUE_APP_MAPBOX_STYLE

  constructor() {}

  onAdd(map) {
    this.map = map

    this._container = document.createElement("div")
    this._container.className = "maplibregl-ctrl maplibregl-ctrl-group"
    this._container.addEventListener("contextmenu", (e) => e.preventDefault())
    this._container.addEventListener("click", (e) => this.showbase())

    this._container.innerHTML = '<div class="tools-box">' + "<button>" + '<span class="maplibregl-ctrl-icon ___my-image-button-S" aria-hidden="true" title="Satellite Icon"><?xml version="1.0" encoding="iso-8859-1"?><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 58 58" xml:space="preserve"><g><polygon style="fill:#26B99A;" points="29,58 3,45 3,13 29,26 	"/><polygon style="fill:#556080;" points="29,58 55,45 55,13 29,26 	"/><polygon style="fill:#434C6D;" points="3,13 28,0 55,13 29,26 	"/></g></svg></span>' + "</button>" + "</div>"

    return this._container
  }

  onRemove() {
    //this.container.parentNode.removeChild(this.container)
    this.map = undefined
  }

  showbase() {
    this.map.setStyle(this.mapboxStyle, {
      transformStyle: (previousStyle, nextStyle) => {
        var custom_layers = previousStyle.layers.filter((layer) => {
          return layer.id.startsWith("3d")
        })
        var layers = nextStyle.layers.concat(custom_layers)

        var sources = nextStyle.sources
        for (const [key, value] of Object.entries(previousStyle.sources)) {
          if (key.startsWith("base")) {
            sources[key] = value
          }
        }
        return {
          ...nextStyle,
          sources: sources,
          layers: layers,
        }
      },
    })

    this.map.flyTo({center: this.map.getCenter()})
  }
}

export const baseStyleControl = new BaseStyleControl()
