class SateliteObjectControl {
  map: any
  private _container: HTMLDivElement
  

  constructor() {}

  onAdd(map) {
    this.map = map

    this._container = document.createElement("div")
    this._container.className = "maplibregl-ctrl maplibregl-ctrl-group"
    this._container.addEventListener("contextmenu", (e) => e.preventDefault())
    this._container.addEventListener("click", (e) => this.showsatellite())

    this._container.innerHTML = '<div class="tools-box">' + "<button>" + '<span class="maplibregl-ctrl-icon ___my-image-button-S" aria-hidden="true" title="Satellite Icon"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>globe-glyph</title><path d="M350.58,277.41A316.23,316.23,0,0,1,256,482.67a316.23,316.23,0,0,1-94.58-205.25ZM512,234.59C502,113.62,407.83,16.23,288.34,1.15a359,359,0,0,1,105.2,233.44Zm-161.42,0A316.23,316.23,0,0,0,256,29.33a316.23,316.23,0,0,0-94.58,205.25ZM288.34,510.85C407.83,495.77,502,398.38,512,277.41H393.53A359,359,0,0,1,288.34,510.85ZM0,277.41c10,121,104.17,218.36,223.66,233.44a359,359,0,0,1-105.2-233.44ZM223.66,1.15C104.17,16.23,10,113.62,0,234.59H118.47A359,359,0,0,1,223.66,1.15Z"/></svg></span>' + "</button>" + "</div>"

    return this._container
  }

  onRemove() {
    //this.container.parentNode.removeChild(this.container)
    this.map = undefined
  }

  showsatellite() {
    this.map.setStyle("https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte-imagery.vt/style.json", {
      transformStyle: (previousStyle, nextStyle) => {
        var custom_layers = previousStyle.layers.filter((layer) => {
          return layer.id.startsWith("3d")
        })
        var layers = nextStyle.layers.concat(custom_layers)

        var sources = nextStyle.sources
        for (const [key, value] of Object.entries(previousStyle.sources)) {
          if (key.startsWith("base")) {
            sources[key] = value
          }
        }
        return {
          ...nextStyle,
          sources: sources,
          layers: layers,
        }
      },
    })

    this.map.flyTo({center: this.map.getCenter()})
  }
}

export const sateliteControl = new SateliteObjectControl()
