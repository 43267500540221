<template>
  <v-dialog v-model="data.plotItemViewActive" max-width="900">
    <v-card class="d-flex flex-column" v-if="plotItem">
      <v-btn fab elevation="0" class="red--text align-self-end ma-2" x-small @click="clearClickView"><v-icon>mdi-close</v-icon></v-btn>
      <v-row>
        <v-col cols="4">
          <div class="v-image v-responsive theme--dark" style="height: 250px">
            <canvas class="v-image__image v-image__image--cover" id="canvas" width="300" height="300"></canvas>
          </div>
          <v-btn color="red lighten-2" text @click="fullReportPDF(plotItem)"> Full Report (PDF) </v-btn>
        </v-col>
        <v-col cols="8">
          <div>
            <div>
              <v-card-title>
                CH-{{ plotItem.STATE }}-{{ plotItem.LOCNAME }}-{{ plotItem.PRCNO }}
                <v-spacer></v-spacer>
                <span v-if="plotItem.RADON > 10" style="color: red; font-size: 20px">&#9888;</span>
              </v-card-title>
              <v-card-subtitle>Parcel ID: {{ plotItem.PRCID }}</v-card-subtitle>

              <v-card-text>
                <div class="d-flex align-center"><img height="32" :src="'/static/kantons/' + String(plotItem.CANTON).toLowerCase() + '.png'" /> {{ plotItem.CANTON }}</div>

                <div class="my-4">{{ plotItem.STREET_ADDRESS.trim() || "No address" }} • {{ plotItem.RADON || "N/A" }} <span class="grey--text">radon</span> • {{ plotItem.YROFBUILD || "N/A" }} <span class="grey--text">built</span></div>

<div class="my-2">{{ plotItem.LOCNAME }} • {{ plotItem.PRCPRICEM2 }} <span class="grey--text">m2</span> • {{ plotItem.PRCSIZE }} <span class="grey--text">size</span> • {{ plotItem.FLOORSIB }} <span class="grey--text">floors</span></div>

                
                <div>{{ plotItem.ZON_TYP1 }} <span class="grey--text">zone 1</span> • {{ plotItem.ZON_TYP2 }} <span class="grey--text">zone 2</span></div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-tabs dense align-with-title next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active v-model="tabViewDetails">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab> ALPHA </v-tab>
                <v-tab> BRAVO </v-tab>
                <v-tab> FIX </v-tab>
                <v-tab> OTHER </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabViewDetails">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <ul>
                        <li>ALPHA_PRI: {{ plotItem.ALPHA_PRI }}</li>
                        <li>ALPHA_SEC: {{ plotItem.ALPHA_SEC }}</li>
                        <li>ALPHA_TER: {{ plotItem.ALPHA_TER }}</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <ul>
                        <li>BRAVO_PRI: {{ plotItem.BRAVO_PRI }}</li>
                        <li>BRAVO_SEC: {{ plotItem.BRAVO_SEC }}</li>
                        <li>BRAVO_TER: {{ plotItem.BRAVO_TER }}</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <ul>
                        <li>FIXELE: {{ plotItem.FIXELE }}</li>
                        <li>FIXID: {{ plotItem.FIXID }}</li>
                        <li>FIXNAME: {{ plotItem.FIXNAME }}</li>
                        <li>FIXNO: {{ plotItem.FIXNO }}</li>
                        <li>FIXTYP: {{ plotItem.FIXTYP }}</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <ul>
                        <li>FREEWAY: {{ plotItem.FREEWAY }}</li>
                        <li>MONUCAT: {{ plotItem.MONUCAT }}</li>
                        <li>MONUNO: {{ plotItem.MONUNO }}</li>
                        <li>MONUOBJ: {{ plotItem.MONUOBJ }}</li>
                        <li>PUTRA: {{ plotItem.PUTRA }}</li>
                        <li>THERMAL: {{ plotItem.THERMAL }}</li>
                        <li>UNITSIB: {{ plotItem.UNITSIB }}</li>
                        <li>PUTRA: {{ plotItem.PUTRA }}</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>

              <v-card-text class="d-none">
                <v-chip-group __v-model="selection" active-class="deep-purple accent-4 white--text" column>
                  <v-chip>5:30PM</v-chip>

                  <v-chip>7:30PM</v-chip>

                  <v-chip>8:00PM</v-chip>

                  <v-chip>9:00PM</v-chip>
                </v-chip-group>
              </v-card-text>
            </div>

            <v-divider></v-divider>
            <div class="mt-3">
              <b>Object Details ({{ objectItems ? objectItems.length : 0 }})</b>
            </div>

            <v-tabs dense align-with-title next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active v-model="tabViewObject">
              <v-tabs-slider color="blue"></v-tabs-slider>
              <v-tab v-for="(res, index) of objectItems" :key="index"> {{ res.OBJID }} </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabViewObject">
              <v-tab-item v-for="(res, index) of objectItems" :key="index + '-det'">
                <v-card flat>
                  <v-card-text
                    >OBJ ID: {{ res.OBJID }}

                    <div>
                      <b>ADRNO</b>: <span>{{ res.ADRNO }}</span>
                    </div>
                    <div>
                      <b>ALPHA_PRI</b>: <span>{{ res.ALPHA_PRI }}</span>
                    </div>
                    <div>
                      <b>ALPHA_SEC</b>: <span>{{ res.ALPHA_SEC }}</span>
                    </div>
                    <div>
                      <b>ALPHA_TER</b>: <span>{{ res.ALPHA_TER }}</span>
                    </div>
                    <div>
                      <b>BRAVO_PRI</b>: <span>{{ res.BRAVO_PRI }}</span>
                    </div>
                    <div>
                      <b>BRAVO_SEC</b>: <span>{{ res.BRAVO_SEC }}</span>
                    </div>
                    <div>
                      <b>BRAVO_TER</b>: <span>{{ res.BRAVO_TER }}</span>
                    </div>
                    <div>
                      <b>CANTON</b>: <span>{{ res.CANTON }}</span>
                    </div>
                    <div>
                      <b>CONTASTATU</b>: <span>{{ res.CONTASTATU }}</span>
                    </div>
                    <div>
                      <b>CONTATYPE</b>: <span>{{ res.CONTATYPE }}</span>
                    </div>
                    <div>
                      <b>FIXELE</b>: <span>{{ res.FIXELE }}</span>
                    </div>
                    <div>
                      <b>FIXID</b>: <span>{{ res.FIXID }}</span>
                    </div>
                    <div>
                      <b>FIXNAME</b>: <span>{{ res.FIXNAME }}</span>
                    </div>
                    <div>
                      <b>FIXNO</b>: <span>{{ res.FIXNO }}</span>
                    </div>
                    <div>
                      <b>FIXTYP</b>: <span>{{ res.FIXTYP }}</span>
                    </div>
                    <div>
                      <b>FLOORSIB</b>: <span>{{ res.FLOORSIB }}</span>
                    </div>
                    <div>
                      <b>FREEWAY</b>: <span>{{ res.FREEWAY }}</span>
                    </div>
                    <div>
                      <b>LOCNAME</b>: <span>{{ res.LOCNAME }}</span>
                    </div>
                    <div>
                      <b>MONUCAT</b>: <span>{{ res.MONUCAT }}</span>
                    </div>
                    <div>
                      <b>MONUNO</b>: <span>{{ res.MONUNO }}</span>
                    </div>
                    <div>
                      <b>MONUOBJ</b>: <span>{{ res.MONUOBJ }}</span>
                    </div>
                    <div>
                      <b>OBJID</b>: <span>{{ res.OBJID }}</span>
                    </div>
                    <div>
                      <b>OBJNO</b>: <span>{{ res.OBJNO }}</span>
                    </div>
                    <div>
                      <b>OBJSURFACE</b>: <span>{{ res.OBJSURFACE }}</span>
                    </div>
                    <div>
                      <b>OBJUSE</b>: <span>{{ res.OBJUSE }}</span>
                    </div>
                    <div>
                      <b>POINAME</b>: <span>{{ res.POINAME }}</span>
                    </div>
                    <div>
                      <b>POITYPE</b>: <span>{{ res.POITYPE }}</span>
                    </div>
                    <div>
                      <b>PRCID</b>: <span>{{ res.PRCID }}</span>
                    </div>
                    <div>
                      <b>PRCNO</b>: <span>{{ res.PRCNO }}</span>
                    </div>
                    <div>
                      <b>PRCPRICEM2</b>: <span>{{ res.PRCPRICEM2 }}</span>
                    </div>
                    <div>
                      <b>PRCSIZE</b>: <span>{{ res.PRCSIZE }}</span>
                    </div>
                    <div>
                      <b>PUTRA</b>: <span>{{ res.PUTRA }}</span>
                    </div>
                    <div>
                      <b>RADON</b>: <span>{{ res.RADON }}</span>
                    </div>
                    <div>
                      <b>SOIL</b>: <span>{{ res.SOIL }}</span>
                    </div>
                    <div>
                      <b>STATE</b>: <span>{{ res.STATE }}</span>
                    </div>
                    <div>
                      <b>STREET</b>: <span>{{ res.STREET }}</span>
                    </div>
                    <div>
                      <b>STREET_ADDRESS</b>: <span>{{ res.STREET_ADDRESS }}</span>
                    </div>
                    <div>
                      <b>THERMAL</b>: <span>{{ res.THERMAL }}</span>
                    </div>
                    <div>
                      <b>UNITSIB</b>: <span>{{ res.UNITSIB }}</span>
                    </div>
                    <div>
                      <b>X</b>: <span>{{ res.X }}</span>
                    </div>
                    <div>
                      <b>Y</b>: <span>{{ res.Y }}</span>
                    </div>
                    <div>
                      <b>YROFBUILD</b>: <span>{{ res.YROFBUILD }}</span>
                    </div>
                    <div>
                      <b>ZON_TYP1</b>: <span>{{ res.ZON_TYP1 }}</span>
                    </div>
                    <div>
                      <b>ZON_TYP2</b>: <span>{{ res.ZON_TYP2 }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component({
  components: {},
})
export default class ViewPlotDetails extends Vue {
  @Prop()
  plotItem

  @Prop()
  objectItems

  tabViewObject = 0
  tabViewDetails = 0

  data: any = {
    items: [],
    plotItemViewActive: false,
  }

  async created() {}

  @Watch("plotItem")
  async onPlotItemChange(val) {
    console.log("plotItem for view has been updated")
    if (val) {
      this.data.plotItemViewActive = true
    } else {
      this.data.plotItemViewActive = false
    }
  }

  @Watch("objectItems")
  async onObjectItemsChange() {
    console.log("objectItems for view has been updated")
  }

  async fullReportPDF(plotItem) {
    console.log("fullReportPDF", plotItem)
    this.$emit("onFullReport", plotItem)
  }

  async clearClickView() {
    this.$emit("onClearClickView")
  }
}
</script>
