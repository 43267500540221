import ServerApi from "./../../libs/api-services/server-api"

class ProfileService extends ServerApi {

  async authorize() {
    return await super.authorize()
  }

  getProfile(){
    return super.getProfile()
  }

}
export const profileService = new ProfileService()
