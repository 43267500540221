<template>
  <div fluid>
    <v-row dense>
      <v-col col="12" md="12">
        <v-card>
          <v-card-title>Notifications</v-card-title>
          <v-card-text>
            <div class="row" v-if="data.notifications.support">
              <div class="col-12 col-md-12">
                <v-switch v-model="data.notifications.pushAll" :label="`Enable Push Notifications`"></v-switch>
              </div>
            </div>
            <div v-else>
              <p>Your browser does not support Push Notifications.</p>
              <p>See browser support for <a href="https://caniuse.com/push-api" target="_blank">Browser Push API</a></p>
            </div>
            <div v-if="data.message">
              <p>{{ data.message }}</p>
            </div>
            <div v-if="data.notifications.support && data.subscription">
              <v-btn @click="test" small dark class="mt-3" color="green">Send Test Notification</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator"
import store from "./../../store"
import {userService} from "./../../libs/user/user-service"
import {preferenceService} from "./preferences-service"
import {settingsService} from "./settings-service"
import {PUSH_APPLICATION_SERVER_PUBLIC_KEY, utils} from "./../../libs/utils"
import eventBus from "../../eventBus"

@Component({
  components: {},
})
export default class Home extends Vue {
  serviceWorkerRegistration = this.$store.state.serviceWorkerRegistration

  data: any = {
    message: "",
    step: "settings",
    rules: {},
    profile: {},
    subscription: null,
    notifications: {
      support: false,
      pushAll: false,
    },
    edit: false,
    user: {
      password: "",
      confirmPassword: "",
    },
    preference: {
      emailSignature: "",
    },
    item: {
      email: "",
      phone: "",
      name: "",
    },
  }

  dashboardUser = "admin"

  constructor() {
    super()
  }

  created() {
    this.serviceWorkerRegistration = this.$store.state.serviceWorkerRegistration
    eventBus.$on("notification-registration", this.reg)

    this.init()
    this.load()
    setTimeout(() => {
      this.checkUserIsSubscribedToPush()
    }, 100)
  }
  beforeDestroy() {
    eventBus.$off("notification-registration", this.reg)
  }

  reg(payload) {
    //{registered:true, reg:swreg}
    console.log("payload registration, ", payload)
    setTimeout(() => {
      this.serviceWorkerRegistration = this.$store.state.serviceWorkerRegistration
      this.checkUserIsSubscribedToPush()
    }, 100)
  }

  edit() {
    this.data.edit = !this.data.edit
  }

  async load() {
    let result: any = {}
    if (this.dashboardUser == "admin") {
      result = await userService.loadProfile()
      if (result) {
        Vue.set(this.data, "profile", result)
        this.data.item = Object.assign({}, result)
      }
    } else {
      //result = await teamMemberService.loadProfile()
      if (result && result.data) {
        Vue.set(this.data, "profile", result.data)
        this.data.item = Object.assign({}, result.data)
      }
    }

    if (result && result.error == true) {
      utils.showSnackbar("Error loading profile")
      return
    }

    let pref = await preferenceService.getPreference()
    if (pref) {
      Vue.set(this.data, "preference", pref)
    }
  }

  @Watch("data.notifications.pushAll")
  changedPushAll(newValue) {
    console.log("newValue", newValue)
    if (newValue == true) {
      this.requestPermission()
    } else {
      this.unsubscribeUser()
    }
  }

  test() {
    if (this.data.subscription) {
      console.log("Sending test")
      settingsService.testPush({subscription: this.data.subscription, username: this.data.profile.username})
    }
  }
  requestPermission() {
    Notification.requestPermission((status) => {
      console.log("Notification permission status:", status)
      if (status == "granted") {
        this.subscribeUser()
      }
      if (status == "denied") {
        utils.showSnackbarError("Error! Push Notifications access denied. Enable Push Notification in your browser and try again")
        this.data.notifications.pushAll = false
      }
    })
  }
  updateSubscriptionOnServer(subscription) {
    console.log("updateSubscriptionOnServer", subscription)
    // Here's where you would send the subscription to the application server
    let username = this.data.profile.username
    if (subscription) {
      settingsService.subscription({
        subscription: subscription,
        username: username,
      })
    } else {
      if (this.data.subscription) {
        let endpoint = this.data.subscription.endpoint
        settingsService.subscription({
          subscription: null,
          endpoint: endpoint,
          username: username,
        })
      } else {
        console.log("Cant remoe subscription without endpoint")
      }
    }
  }
  subscribeUser() {
    console.log("subscribeUser")
    if (!this.serviceWorkerRegistration) {
      console.log("Error. this.serviceWorkerRegistration is null")
      return
    }
    const applicationServerKey = utils.urlB64ToUint8Array(PUSH_APPLICATION_SERVER_PUBLIC_KEY)
    this.serviceWorkerRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey,
      })
      .then((subscription) => {
        console.log("User is subscribed:", subscription)
        this.data.subscription = subscription

        this.updateSubscriptionOnServer(subscription)

        this.data.notifications.pushAll = true

        this.data.message = "You are subscribed to receive Push Notifications on this device"
      })
      .catch((err) => {
        if (Notification.permission === "denied") {
          console.warn("Permission for notifications was denied")
          this.data.message = "Error! Push notification is denied on this browser. Please enable push notifications and try again"
        } else {
          console.error("Failed to subscribe the user: ", err)
          this.data.message = "Error! Failed to subscribe user to push notification."
        }
      })
  }

  unsubscribeUser() {
    console.log("unsubscribeUser")
    if (!this.serviceWorkerRegistration) {
      console.log("Error. this.serviceWorkerRegistration is null")
      return
    }
    this.serviceWorkerRegistration.pushManager
      .getSubscription()
      .then((subscription) => {
        if (subscription) {
          return subscription.unsubscribe()
        }
      })
      .catch((err) => {
        console.log("Error unsubscribing", err)
      })
      .then(() => {
        this.updateSubscriptionOnServer(null)
        this.data.subscription = null

        console.log("User is unsubscribed")
        this.data.notifications.pushAll = false

        this.data.message = "You are not subscribed to receiving Push Notifications on this device"
      })
  }

  checkUserIsSubscribedToPush() {
    console.log("checkUserIsSubscribedToPush")
    if (!this.serviceWorkerRegistration) {
      console.log("Error. this.serviceWorkerRegistration is null")
      return
    }
    this.serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
      this.data.subscription = subscription
      let isSubscribed = subscription !== null

      if (isSubscribed) {
        console.log("User IS subscribed.")
        this.data.notifications.pushAll = true
        this.data.message = "You will receive Push Notifications on this device"
      } else {
        console.log("User is NOT subscribed.")
        this.data.notifications.pushAll = false
        this.data.message = "You are not subscribed to receiving Push Notifications on this device"
      }
    })
  }

  async init() {
    this.data.profile = store.getters.profile
    this.data.notifications.support = false

    if (!("Notification" in window)) {
      console.log("Notifications not supported in this browser")
      utils.showSnackbarError("Notifications not supported in this browser")
      return
    } else {
      console.log("Notifications supported in this browser")
      this.data.notifications.support = true
    }
  }
}
</script>
