import {config} from "./../utils"
import BaseApi from "./base-api"
import {cacheSrv} from "./../cache/cache"

export default class SSOApi extends BaseApi {
  apiUrl = config.VUE_APP_SSO_API
  token

  loadHeaders(headers: any = {}) {
    if (Object.keys(headers).length == 0) {
      let token = this.getToken()
      if (!token) {
        console.log("Error - no token to authorise this call")
      } else {
        return {headers: {authorization: "Bearer " + token}}
      }
    }
    return {headers: headers}
  }

  getToken() {
    if (!this.token) {
      this.token = cacheSrv.cached(this.CACHE_KEY_SSO_TOKEN)
    }
    if (!this.token) {
      return null
    }
    return this.token
  }
}
