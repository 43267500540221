<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Reset password</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="false">
              <template v-slot:activator="{on}">
                <v-btn href="/register" icon large v-on="on">
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </template>
              <span>Request Registration</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <span class="caption grey--text text--darken-1">
              Please enter a new password for your account
            </span>
            <v-text-field id="password" label="Password" v-model="user.password" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>
            <v-text-field id="password" label="Confirm Password" v-model="user.confirmPassword" name="confirmPassword" prepend-icon="mdi-lock" type="password"></v-text-field>

            <div class="m-4 red--text" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" :disabled="processing" @keyup.enter="resetPassword()" type="submit" @click="resetPassword()">{{ processing ? "Authenticating..." : "RESET PASSWORD" }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="showLogin" @click="gotoLogin()">Go to Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import router from "../../router"
import axios from "axios"
import {config, utils} from "../utils"

@Component({
  components: {},
})
export default class ResetPassword extends Vue {
  ssoApiUrl = config.VUE_APP_SSO_API

  user = {
    password: "",
    confirmPassword: "",
  }
  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  params
  showLogin

  constructor() {
    super()
  }

  created() {
    this.showLogin = false
    this.params = this.$route.params
    if (!this.params.key) {
      console.log("You have no key")
    }
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }
  async resetPassword() {
    this.errorMessage = ""
    this.processing = true
    let result
    if (!this.user.password) {
      this.errorPass()
      this.errorMessage = "Please enter new password"
      this.processing = false
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/reset-password`, {
        password: this.user.password.trim(),
        confirmPassword: this.user.confirmPassword.trim(),
        key: this.params.key,
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while updating new password")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
    this.processing = false
    if (result.data) {
      let data = result.data
      console.log("data", data)
      if (data.error == true) {
        this.errorPass()
        console.log("Error in processing resetPassword ")
        this.errorMessage = data.message
        this.showLogin = true
        return
      }
      utils.showSnackbarSuccess("Success. Redirecting to login page...")
      setTimeout(function() {
        router.push("/login")
      }, 1280)
    } else {
      console.log("Error in validating username. No data")
    }
  }
  gotoLogin() {
    router.push("/login")
  }
}
</script>
