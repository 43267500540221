<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <v-card class="pa-5" style="opacity: 0.88">
          <div class="title text-center">Registration Terarra User/Agent</div>

          <v-card-text>
            <v-window v-model="data.step">
              <v-window-item :value="'register'">
                <v-card-text>
                  <h4>Company/Agent Details</h4>
                  <v-form aria-autocomplete="off" autocomplete="off" @submit.prevent="register" v-on:submit.prevent>
                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Company Name" name="companyName" prepend-icon="mdi-bank" v-model="user.companyName" type="text"></v-text-field>

                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Full Name" name="name" prepend-icon="mdi-account" v-model="user.name" type="text"></v-text-field>
                    
                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Phone Number" name="phoneNumber" prepend-icon="mdi-phone" v-model="user.phone" type="text"></v-text-field>
                    
                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Company Address" name="companyAddress" prepend-icon="mdi-map-marker" v-model="user.companyAddress" type="text"></v-text-field>

                    <h4 class="mt-5">User Login Details</h4>
                    <v-text-field autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" label="Email" name="username" prepend-icon="mdi-email" v-model="user.username" type="email"></v-text-field>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field id="password" label="Password" v-model="user.password" name="password" prepend-icon="mdi-lock" @keyup.enter="register()" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min, rules.strength]" validate-on-blur :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" hint="Try a mix of letters, numbers, and symbols. Min 8 characters" loading>
                          <template v-slot:progress>
                            <v-progress-linear absolute height="7" :color="checkPasswordStrength <= 4 ? 'red' : 'green'" :value="checkPasswordStrength * 20"></v-progress-linear>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field id="confirmPassword" label="Confirm Password" v-model="user.confirmPassword" name="confirmPassword" prepend-icon="mdi-lock" type="password" @keyup.enter="register()"></v-text-field>
                      </v-col>
                      <v-col> </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="'register-done'">
                <v-card-text>
                  <h4>Registration Successfull / Pending Activation</h4>
                  <p>Your registration is succefful. We have emailed instructions on how to activate this account.</p>
                  <p>If you do not receive email in next few minutes, please check spam folder or contact us for assistance.</p>
                </v-card-text>
              </v-window-item>
            </v-window>

            <div class="m-4 red--text" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-if="data.step == 'register'" :disabled="processing" @keyup.enter="register()" type="submit" @click="register()">{{ processing ? "Registering..." : "REGISTER" }}</v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            Already have an account? <v-btn to="/login" color="secondary" type="button" text>Login </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator"
import {cacheSrv} from "../cache/cache"
import router from "./../../router"
import {registerService} from "./register-service"
import {profileService} from "./profile-service"
import {userService} from "./user-service"
import {config, utils} from "../utils"
import axios from "axios"

@Component({
  components: {},
})
export default class Register extends Vue {
  ssoApiUrl = config.VUE_APP_SSO_API
  homeUrl = config.VUE_APP_APP_URL

  showPassword = false
  rules = {
    required: (value) => !!value || "Enter a password",
    min: (v) => v.length >= 8 || "Use 8 characters or more for your password",
    strength: (v) => {
      return this.checkPasswordStrength >= 5 || "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
    },
  }

  user = {
    username: "",
    password: "",
    companyName: "",
    name: "",
    phone: "",
    companyAddress: "",
    confirmPassword: "",
  }
  data: any = {
    step: "register",
  }

  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  constructor() {
    super()
  }

  async register() {
    //Clean up stored profile
    cacheSrv.remove("profile")

    console.log("register")
    this.errorMessage = ""
    this.processing = true
    let result

    if (!this.user.companyName || !this.user.name || !this.user.companyAddress || !this.user.username || !this.user.password || !this.user.confirmPassword) {
      this.errorMessage = "Please fill in all fields"
      this.processing = false
      return
    }
    //add check of password and confirm password
    if (this.user.password !== this.user.confirmPassword) {
      this.errorMessage = "Password and Confirm Password do not match"
      this.processing = false
      return
    }

    try {
      result = await registerService.register(this.user)
      this.processing = false
      if (result) {
        let data = result

        if (data.error) {
          this.errorPass()
          console.log("Error in registration")
          this.errorMessage = data.message
          return
        }

        if (data["token"]) {
          this.errorMessage = ""
          await userService.initUser()
          await profileService.authorize()

          setTimeout(function () {
            router.push("/")
          }, 888)
        } else {
          console.log("no token")
          this.errorPass()
        }
      } else {
        console.log("Error in validating username. No data")
      }
    } catch (e) {
      console.log("error logging in", e)
      this.errorPass()
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }

  get checkPasswordStrength() {
    let password = this.user.password
    let strength = 0
    if (password.match(/[a-z]+/)) {
      strength += 1
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1
    }
    if (password.match(/[0-9]+/)) {
      strength += 1
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1
    }
    if (password.length > 8) {
      strength += 1
    }
    return strength
  }
}
</script>
