import eventBus from "@/eventBus"
import io from "socket.io-client"
import {config} from "./../../libs/utils"

class SocketService {
  socketUrl = config.VUE_APP_SOCKET_SERVER
  socketPath = config.VUE_APP_SOCKET_PATH

  socket: any
  profile = {}
  token
  observers = [{}]
  senderEventRegistered = false
  registerUpdateObserver(callback) {
    this.observers.push(callback)
  }
  async initSocket(token) {
    let socketUrl = this.socketUrl
    let socketPath = this.socketPath
    this.token = token
    let options: any = {
      path: socketPath,
      query: {
        token: token,
      },
      timeout: 5000,
      transports: ["websocket"],
    }
    if (socketUrl && socketUrl.includes("https://")) {
      options.secure = true
      options.rejectUnauthorized = false
    }

    console.log("initialising Sockets", socketUrl, socketPath)
    this.socket = io(socketUrl, options)
    this.socket.on("message", (msg) => {
      if (msg.type) {
        console.log("SOCKET 'message' event RECEIVED msg.type", msg.type, ". Emitting it now to all listeners")
        eventBus.$emit(msg.type, msg.data)
      }
    })
    this.socket.on("error", (msg) => {
      console.log("Error connecting to s", msg)
      
    })
    if (!this.senderEventRegistered) {
      this.senderEventRegistered = true
      eventBus.$on("socket-message", this.onNewSendMessageRequest)
    }
  }
  onNewSendMessageRequest(payload) {
    let message = "message"
    console.log("sedning message to secoket", message, payload)
    if (this.socket) {
      this.socket.emit(message, payload)
    } else {
      console.log("No socket component ready")
    }
  }

  sendMessageToSocket = (type, payload) => {
    // console.log("sedning message to secoket", type, payload)
    if (this.socket) {
      this.socket.emit("message", {type:type, data:payload})
    } else {
      console.log("No socket component ready")
    }
  }
}
export const socketService = new SocketService()
