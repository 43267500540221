import {cacheSrv} from "../cache/cache"
import BaseDomainService from "../domain/base-domain-service"
import {config} from "../utils"

export default class ServerReportApi extends BaseDomainService {
  apiUrl = config.VUE_APP_SERVER_IMPORTER_API
  CACHE_KEY_SERVER = "sm_map_importer"
  token
  profile

  async precheck(method, url, payload, headers) {
    console.log("precheck method/url", method, url)
    this.getToken()
    if (!this.token) {
      let success = await this.authorize()
      if (!success) {
        console.log("Blocking access to server api. No token")
        return false
      }
    }
    return true
  }

  loadHeaders(headers: any = {}) {
    if (Object.keys(headers).length == 0) {
      let token = this.getToken()
      if (!token) {
        console.log("Error - no token to authorise this call")
      } else {
        return {headers: {authorization: "Bearer " + token}}
      }
    }
    return {headers: headers}
  }

  async authorize() {
    let data = await super.authorize(this.apiUrl + "/v1/secure/user/authorize", this.getSSOToken())
    if (data && data["token"]) {
      cacheSrv.cacheEx(this.CACHE_KEY_SERVER + "_tok", data["token"], 60 * 60 * 24 * 360)
      this.storeProfileLocally(data["profile"])
      return true
    }
    return false
  }

  storeProfileLocally(profile: any) {
    this.profile = profile
    cacheSrv.cacheEx(this.CACHE_KEY_SERVER + "_usr", this.profile, 60 * 60 * 24 * 360)
  }

  getToken() {
    if (!this.token) {
      this.token = cacheSrv.cached(this.CACHE_KEY_SERVER + "_tok")
    }
    if (!this.token) {
      return null
    }
    return this.token
  }
}
