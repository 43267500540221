import store from "./../../store"
import router from "./../../router"
import {cacheSrv} from "./../cache/cache"
import {socketService} from "./../socket/socket-service"
import SSOApi from "../api-services/server-sso-api"

class UserService extends SSOApi {
  profile
  SYSTEM_ACCESS_ROLE_PREFIX = "MAPUSER"

  async initUser() {
    console.log("initUser")
    let token = cacheSrv.cached(this.CACHE_KEY_SSO_TOKEN)
    if (!token) {
      console.log("Cant init user - no token")
      return false
    } else {
      console.log("updateToken")
      this.token = token
      store.commit(`updateToken`, token)
      socketService.initSocket(token)
      await this.loadUserProfile()
    }
    return true
  }

  async loadUserProfile() {
    console.log("loadUserProfile")
    if (this.token) {
      let data

      let profile = cacheSrv.cached("profile")
      if (profile) {
        console.log("profile ok")
        this.profile = profile
        store.commit(`updateProfile`, this.profile)
        return
      }

      //No user in local storage, load it from SSO
      try {
        data = await this.get(`/api/admin/profile`)
      } catch (e) {
        console.log("Error while loading profile", e)
        return
      }
      if (data) {
        if (data["error"]) {
          console.log("Error in validating number")
          return
        }
        console.log("storeProfileLocally")
        this.storeProfileLocally(data)
      } else {
        console.log("Error in validating username. No data")
      }
    } else {
      console.log("Cant load user profile - missing token")
    }
  }

  loadProfile() {
    let profile = cacheSrv.cached("profile")
    return profile
  }
  storeProfileLocally(profile: any) {
    this.profile = profile
    cacheSrv.cacheEx("profile", this.profile, 60 * 10)
    store.commit(`updateProfile`, this.profile)
  }

  async saveProfile(payload: any) {
    return await this.post(`/profile`, payload)
  }

  getToken() {
    return this.token
  }

  getProfile() {
    return this.profile
  }

  hasRole(role: string) {
    return this.profile.roles.includes(role)
  }
  hasAccessRight(accessRight: string) {
    if (this.profile.accessRights) {
      return this.profile.accessRights.includes(accessRight)
    }
    return false
  }
  hasAnyRole(roles: any) {
    let hasAccess = false
    if (!Array.isArray(roles)) {
      roles = [roles]
    }
    if (!this.profile.roles) {
      return false
    }
    roles.forEach((role) => {
      let hasOne = this.profile.roles.includes(role)
      if (hasOne) {
        hasAccess = true
      }
    })
    return hasAccess
  }

  hasAccessToSite(siteID: string) {
    if (this.profile.roles.includes("ADMIN")) {
      //Admin  can see all
      return true
    }
    if (this.profile.sites) {
      let sites = this.profile.sites.map((i) => i.siteID)
      console.log("this.profile.sites", this.profile, sites, siteID)
      return sites.includes(siteID)
    }
    return false
  }

  getAccessLevel() {
    let userProfile = this.getProfile()
    let roles = userProfile ? userProfile["roles"] : []
    let level = 0
    const sysRolePos = roles.findIndex((i) => {
      return i.indexOf(this.SYSTEM_ACCESS_ROLE_PREFIX + ":") >= 0 ? true : false
    })
    if (sysRolePos >= 0) {
      const sysRole = roles[sysRolePos]
      const sysRoleArr = sysRole.split(":")
      if (sysRoleArr.length >= 2) {
        const LEVEL = Number(sysRole.split(":")[1])
        level = LEVEL
      }
    }
    return level
  }

  hasAccess(rolesRequired) {
    let userProfile = this.getProfile()
    if (userProfile) {
      let s = this.hasAccessForRoles(rolesRequired, userProfile["roles"])
      return s
    } else {
      return false
    }
  }
  hasAccessForRoles(rolesRequired, userRoles) {
    let allow = false
    if (rolesRequired && !Array.isArray(rolesRequired)) {
      rolesRequired = rolesRequired.split(",").map((i) => i.trim())
    }
    if (!rolesRequired || rolesRequired.length == 0) {
      allow = true
      return allow
    }
    if (
      rolesRequired.filter((r) => {
        return r.startsWith("=")
      }).length == 0
    ) {
      rolesRequired = rolesRequired.concat(["SUPER"])
    }

    //Loop over each role anmd extract level
    rolesRequired.forEach((roleRequired) => {
      let requiredAppPrefix = roleRequired.split(":")[0]
      let requiredLevel = roleRequired.indexOf(":") > 0 ? roleRequired.split(":")[1] : 0
      if (userRoles) {
        userRoles.forEach((userRole) => {
          let userAppPrefix = userRole.split(":")[0]
          if (String(roleRequired).startsWith("=")) {
            if (roleRequired.substring(1).toLowerCase() == userRole.toLowerCase()) {
              allow = true
            }
          } else {
            if (requiredAppPrefix.toLowerCase() == userAppPrefix.toLowerCase()) {
              let userLevel = userRole.indexOf(":") > 0 ? userRole.split(":")[1] : 0
              if (Number(userLevel) >= Number(requiredLevel)) {
                allow = true
              }
            }
          }
        })
      }
    })

    return allow
  }

  async saveNewPassword(payload) {
    if (this.token) {
      let result

      try {
        result = this.post(`/api/admin/update-password`, payload)
      } catch (e) {
        console.log("Error while updating pass", e)
        return false
      }
      if (result.data) {
        let data = result.data
        if (data["error"]) {
          console.log("Error in saveNewPassword")
          return false
        }
        return true
      } else {
        console.log("Error in saveNewPassword. No data")
        return false
      }
    }
  }

  redirectToLogin() {
    router.push("login")
  }
}

export const userService = new UserService()
