<template>
  <span>
    <v-autocomplete v-bind:value="value" :disabled="disabled" :auto-select-first="autoSelectFirst" v-on:input="onInput" :rules="rules" :items="data.items" :loading="data.isLoading" :search-input.sync="data.search" item-text="code" item-value="_id" :label="label" :placeholder="'Search'" no-data :no-data-text="'- type to find -'" clearable @focus="onFocus" @change="onInput" :no-filter="true" return-object :required="required" :hint="hint" :persistent-hint="hint ? true : false" flat hide-details></v-autocomplete>
  </span>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {debounce} from "./../../../libs/debounce"
import eventBus from "../../../eventBus"
import {cantonService} from "./service"
import {rules} from "./../../../libs/utils"

@Component({
  components: {},
})
export default class CantonSelect extends Vue {
  @Prop({default: () => []})
  rules

  @Prop({default: false})
  autoSelectFirst

  @Prop({default: false})
  allowNew

  @Prop()
  value

  @Prop()
  required

  @Prop()
  disabled

  @Prop({default: "Canton"})
  label

  @Prop({default: null})
  hint

  @Prop()
  siteID

  data: any = {
    items: [],
    isLoading: false,
    search: null,
  }

  async created() {
    if (this.value && this.value.code) {
      Vue.set(this.data, "items", [this.value])
    }
  }

  onInput(val) {
    this.$emit("input", val)
  }

  async onFocus() {
    this.loadItems()
  }

  @Watch("value")
  onValueSet(val) {
    // if (val) {
    //   Vue.set(this.data, "items", [val])
    // }
  }

  @Watch("data.search")
  find(val) {
    debounce.delay(() => {
      this.loadItems()
    }, 450)
  }

  async loadItems() {
    if (this.data.isLoading) {
      return
    }
    this.data.isLoading = true
    let payload = {
      q: this.data.search,
    }
    try {
      let options = {fields: "code", limit: 25}
      let res = await cantonService.find(payload, options)
      if (res.error == true || res.data == null) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Failed to load items. Please check your internet connection and try again"})
        this.data.isLoading = false
        return
      }

      this.data.items = res.data
      this.data.isLoading = false
    } catch (error) {
      console.log(error)
      this.data.isLoading = false
    }
  }
}
</script>
