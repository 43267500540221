import Vue from "vue"
import VueRouter, {RouteConfig} from "vue-router"
import store from "./../store"

//PUBLIC
import Register from "../libs/user/Register.vue"
import Login from "../libs/user/Login.vue"
import Logout from "../libs/user/Logout.vue"
import ResetPassword from "../libs/user/ResetPassword.vue"
import Invite from "../libs/user/Invite.vue"
import MapSearch from "../features/map/Search.vue"

//USER
import Settings from "../features/user/Settings.vue"
import Notifications from "../features/user/Notifications.vue"

//APP
import Summary from "../features/dashboard/Summary.vue"

//REPORTS
import {utils} from "@/libs/utils"
import { cacheSrv } from "@/libs/cache/cache"

Vue.use(VueRouter)

function hasAccess(rolesRequired, userRoles) {
  let allow = false
  console.log("\nHas Access Condition: Req/User", rolesRequired, "/", userRoles)

  if (!rolesRequired || rolesRequired.length == 0) {
    console.log("/Intercept/ - no requirement for any role. Allowing")
    allow = true
    return allow
  }
  rolesRequired = rolesRequired.concat(["SUPER"])

  //Loop over each role anmd extract level
  rolesRequired.forEach((roleRequired) => {
    let requiredAppPrefix = roleRequired.split(":")[0]
    let requiredLevel = roleRequired.indexOf(":") > 0 ? roleRequired.split(":")[1] : 0

    // loop over user roles and find matching role and level
    userRoles.forEach((userRole) => {
      let userAppPrefix = userRole.split(":")[0]
      if (requiredAppPrefix == userAppPrefix) {
        let userLevel = userRole.indexOf(":") > 0 ? userRole.split(":")[1] : 0
        if (Number(userLevel) >= Number(requiredLevel)) {
          allow = true
        }
      }
    })
  })

  return allow
}

function hasAnyRole(roles: any, inProfileRoles: any) {
  console.log("hasAnyRole", roles, inProfileRoles)
  let hasAccess = false
  if (!Array.isArray(roles)) {
    roles = [roles]
  }
  if (!inProfileRoles) {
    console.log("Profile in Roles false")
    return false
  }
  roles.forEach((role) => {
    let hasOne = inProfileRoles.includes(role)
    if (hasOne) {
      hasAccess = true
    }
  })
  console.log("Returning role hasAccess", hasAccess)
  return hasAccess
}

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Summary",
    component: Summary,
    meta: {layout: "maps",access: ["MAPUSER"]},
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {layout: "maps",access: ["MAPUSER"]},
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: {layout: "maps",access: ["MAPUSER"]},
  },
  {
    path: "/logout",
    name: "logout",
    meta: {layout: "maps",access: ["MAPUSER"]},
    component: Logout,
  },
  {
    path: "/",
    name: "mapSearch",
    meta: {layout: "maps",access: ["MAPUSER"]},
    component: MapSearch,
  },
  {
    path: "/login",
    name: "login",
    meta: {layout: "simple"},
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    meta: {layout: "simple"},
    component: Register,
  },
  {
    path: "/reset-password/:key",
    name: "resetPassword",
    meta: {layout: "simple"},
    component: ResetPassword,
  },
  {
    path: "/invite/:key",
    name: "invite",
    meta: {layout: "simple"},
    component: Invite,
  },
  {path: "*", redirect: "/"},
].map((i: any) => {
  if (!i.meta) {
    i["meta"] = {layout: "app"}
  }
  if (i["meta"] && i["meta"]["access"]) {
    i.beforeEnter = (to, from, next) => {
      if (!store.state.token) {
        next("/login")
        return
      }

      if (store.state.profile && !hasAccess(i["meta"]["access"], store.state.profile["roles"])) {
        if (to.path == "/") {
          cacheSrv.removeAll()
          store.commit(`logout`)
          next("/login")
          return
        }
        console.log("DENIED ACCESS TO ROUTE", to, i["meta"]["access"], store.state.profile["roles"])
        utils.showSnackbarError("DENIED ACCESS")
        return
      }
      next()
    }
  }
  return i
})

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
