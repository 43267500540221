import eventBus from "./../eventBus"
import moment from "moment"
import {appConfig} from "./config"

export const rules = {
  required: (value: any) => !!value || "Required",
  number: (value: any) => !isNaN(value) || "Number only",
  notEmptyString: (value: string) => (value && value.trim() !== "") || "Must not contain only whitespaces",
  maxLength: (value: string, maxLength: number) => (value && value.length <= maxLength) || !value || `Must be less than ${maxLength} characters`,
  valueGreaterThanZero: (value: number) => Number(value) > 0 || "Must be greater than 0",
  valueGreaterThanOne: (value: number) => Number(value) > 1 || "Must be greater than 1",
  valueGreaterOrEqZero: (value: number) => Number(value) >= 0 || "Must be greater than 0",
  valueLessThanOrEqualTo100: (value: number) => Number(value) <= 100 || "Must be not more than 100",
  noDotNoSpace: (value: any) => /^[a-zA-Z0-9_]*$/gm.test(value) || "Alphanumeric with underscore. No spaces, dots, dashes allowed",
  noDotNoSpaceDash: (value: any) => /^[a-zA-Z0-9_-]*$/gm.test(value) || "Alphanumeric with underscore. No spaces, dots, dashes allowed",
  noDot: (value: any) => /^[a-zA-Z0-9_\s-]*$/gm.test(value) || "Alphanumeric with underscore. No dots and special characters",
  email: (value: any) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(value) || !value || value.trim() == "" || "E-mail must be valid",
  requiredNumber: (value: any) => (value !== null && value !== "" && !isNaN(value)) || "Required",
  numberOrEmpty: (value: any) => !value || !isNaN(value) || "Valid Number",
  valueGreaterOrEqualToZero: (value: number) => Number(value) >= 0 || "Must be at least 0",
  valueGreaterOrEqualToZeroOrEmpty: (value: number) => Number(value) >= 0 || !value || "Leave empty or must be at least 0",
  maxLengthForNotRequiredField: (value: string, maxLength: number) => !value || (value && value.trim() !== "" && value.length <= maxLength) || `Must be less than ${maxLength} characters`,
  password: (value: any) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(value) || "Password must have at least 8 characters with lower and upper letters and at least one symbol and one digit", 
}

export const PUSH_APPLICATION_SERVER_PUBLIC_KEY = process.env.PUSH_APPLICATION_SERVER_PUBLIC_KEY || "BBvTNqThmK2396EVUmrCRuZ1Fqka2_0ueyMED8cvDFXIRRA455nQ2D-JxbpL2FurJavwVbrU5vz86vy1frNBKcY"

console.log("appConfig", appConfig)

export const config: any = appConfig

let EODHour = 22
let SODHour = 9

export const datePeriodOptions = [
  {name: "In an hour", value: moment().add(1, "hour")},
  {
    name: "EOD Today",
    value: moment()
      .set("hour", EODHour)
      .startOf("hour"),
  },
  {
    name: "Tomorrow",
    value: moment()
      .add(1, "day")
      .set("hour", SODHour)
      .startOf("hour"),
  },
  {
    name: "EOD Tomorrow",
    value: moment()
      .add(1, "day")
      .set("hour", EODHour)
      .startOf("hour"),
  },
  {
    name: "In 2 Days",
    value: moment()
      .add(2, "day")
      .set("hour", SODHour)
      .startOf("hour"),
  },
  {
    name: "End of Week",
    value: moment()
      .endOf("week")
      .set("hour", EODHour)
      .startOf("hour"),
  },
]

export const dateFilterCommonConfig: any = {
  quick: {
    Today: [
      moment()
        .startOf("day")
        .format("YYYYMMDD"),
      moment()
        .endOf("day")
        .format("YYYYMMDD"),
      {offset: ["days", 1]},
    ],
    Yesterday: [
      moment()
        .startOf("day")
        .add(-1, "days")
        .format("YYYYMMDD"),
      moment()
        .endOf("day")
        .add(-1, "days")
        .format("YYYYMMDD"),
      {offset: ["days", 1]},
    ],
    "This week": [
      moment()
        .startOf("week")
        .format("YYYYMMDD"),
      moment()
        .endOf("week")
        .format("YYYYMMDD"),
      {offset: ["weeks", 1]},
    ],
    "Last week": [
      moment()
        .startOf("week")
        .add(-1, "week")
        .format("YYYYMMDD"),
      moment()
        .endOf("week")
        .add(-1, "week")
        .format("YYYYMMDD"),
      {offset: ["weeks", 1]},
    ],
    "This month": [
      moment()
        .startOf("month")
        .format("YYYYMMDD"),
      moment()
        .endOf("month")
        .format("YYYYMMDD"),
      {offset: ["months", 1]},
    ],
    "Last month": [
      moment()
        .startOf("month")
        .add(-1, "month")
        .format("YYYYMMDD"),
      moment()
        .endOf("month")
        .add(-1, "month")
        .format("YYYYMMDD"),
      {offset: ["months", 1]},
    ],
    "This quarter": [
      moment()
        .startOf("quarter")
        .format("YYYYMMDD"),
      moment()
        .endOf("quarter")
        .format("YYYYMMDD"),
      {offset: ["quarter", 1]},
    ],
    "Last quarter": [
      moment()
        .startOf("quarter")
        .add(-1, "quarter")
        .format("YYYYMMDD"),
      moment()
        .endOf("quarter")
        .add(-1, "quarter")
        .format("YYYYMMDD"),
      {offset: ["quarter", 1]},
    ],
    YTD: [
      moment()
        .startOf("year")
        .format("YYYYMMDD"),
      moment()
        .endOf("day")
        .format("YYYYMMDD"),
      {offset: ["year", 1]},
    ],
    "Last year": [
      moment()
        .add(-1, "year")
        .startOf("year")
        .format("YYYYMMDD"),
      moment()
        .add(-1, "year")
        .endOf("year")
        .format("YYYYMMDD"),
      {offset: ["year", -1]},
    ],
  },

  quickArray: [
    {
      key: "Today",
      value: [
        moment()
          .startOf("day")
          .format("YYYYMMDD"),
        moment()
          .endOf("day")
          .format("YYYYMMDD"),
        {offset: ["days", 1]},
      ],
    },
    {
      key: "Yesterday",
      value: [
        moment()
          .startOf("day")
          .add(-1, "days")
          .format("YYYYMMDD"),
        moment()
          .endOf("day")
          .add(-1, "days")
          .format("YYYYMMDD"),
        {offset: ["days", 1]},
      ],
    },
    {
      key: "This week",
      value: [
        moment()
          .startOf("week")
          .format("YYYYMMDD"),
        moment()
          .endOf("week")
          .format("YYYYMMDD"),
        {offset: ["weeks", 1]},
      ],
    },
    {
      key: "Last week",
      value: [
        moment()
          .startOf("week")
          .add(-1, "week")
          .format("YYYYMMDD"),
        moment()
          .endOf("week")
          .add(-1, "week")
          .format("YYYYMMDD"),
        {offset: ["weeks", 1]},
      ],
    },
    {
      key: "Last 7 days",
      value: [
        moment()
          .add(-1, "week")
          .startOf("day")
          .format("YYYYMMDD"),
        moment()
          .endOf("day")
          .format("YYYYMMDD"),
        {offset: ["months", 1]},
      ],
    },
    {
      key: "This month",
      value: [
        moment()
          .startOf("month")
          .format("YYYYMMDD"),
        moment()
          .endOf("month")
          .format("YYYYMMDD"),
        {offset: ["months", 1]},
      ],
    },
    {
      key: "Last 30 days",
      value: [
        moment()
          .add(-30, "days")
          .startOf("day")
          .format("YYYYMMDD"),
        moment()
          .endOf("day")
          .format("YYYYMMDD"),
        {offset: ["months", 1]},
      ],
    },
    {
      key: "Last month",
      value: [
        moment()
          .startOf("month")
          .add(-1, "month")
          .format("YYYYMMDD"),
        moment()
          .endOf("month")
          .add(-1, "month")
          .format("YYYYMMDD"),
        {offset: ["months", 1]},
      ],
    },
    {
      key: "This quarter",
      value: [
        moment()
          .startOf("quarter")
          .format("YYYYMMDD"),
        moment()
          .endOf("quarter")
          .format("YYYYMMDD"),
        {offset: ["quarter", 1]},
      ],
    },
    {
      key: "Last quarter",
      value: [
        moment()
          .startOf("quarter")
          .add(-1, "quarter")
          .format("YYYYMMDD"),
        moment()
          .endOf("quarter")
          .add(-1, "quarter")
          .format("YYYYMMDD"),
        {offset: ["quarter", 1]},
      ],
    },
    {
      key: "YTD",
      value: [
        moment()
          .startOf("year")
          .format("YYYYMMDD"),
        moment()
          .endOf("day")
          .format("YYYYMMDD"),
        {offset: ["year", 1]},
      ],
    },
    {
      key: "Last year",
      value: [
        moment()
          .add(-1, "year")
          .startOf("year")
          .format("YYYYMMDD"),
        moment()
          .add(-1, "year")
          .endOf("year")
          .format("YYYYMMDD"),
        {offset: ["year", -1]},
      ],
    },
  ],
}

class Utils {
  datePeriodOptions() {
    return [
      {name: "In an hour", value: moment().add(1, "hour")},
      {
        name: "EOD Today",
        value: moment()
          .set("hour", EODHour)
          .startOf("hour"),
      },
      {
        name: "Tomorrow",
        value: moment()
          .add(1, "day")
          .set("hour", SODHour)
          .startOf("hour"),
      },
      {
        name: "EOD Tomorrow",
        value: moment()
          .add(1, "day")
          .set("hour", EODHour)
          .startOf("hour"),
      },
      {
        name: "In 2 Days",
        value: moment()
          .add(2, "day")
          .set("hour", SODHour)
          .startOf("hour"),
      },
      {
        name: "End of Week",
        value: moment()
          .endOf("week")
          .set("hour", EODHour)
          .startOf("hour"),
      },
    ]
  }
  moneyRounding(value, digits = 2) {
    console.log("moneyRounding", value)
    let p = Math.pow(10, digits || 2)
    return Math.round((value + Number.EPSILON) * p) / p
  }
  sameNumbers(n1, n2) {
    n1 = n1 || 0
    n2 = n2 || 0
    let n1f = this.moneyRounding(n1, 3)
    let n2f = this.moneyRounding(n2, 3)
    let diff = n1f - n2f
    if (diff != 0) {
      console.log("sameNumbers match failed", n1f, n2f, diff)
      return false
    }
    return true
  }
  readableAmount(value) {
    let sizes = ["", "K", "M", "B", "T", "G"]
    let dept = this.howManyDepth(0, value || 0)
    // console.log("dept/v", dept, value, Math.pow(1000, dept), sizes[dept])
    let v = Number(value / Math.pow(1000, dept))
    if (dept > 0) {
      v = Number(v.toFixed(2))
    }
    return {value: v, nom: sizes[dept]}
  }
  stringToHslColor(str, s=30, l=100) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }
  howManyDepth(s, v) {
    let a = v / 1000
    if (a >= 1) {
      s++
      return this.howManyDepth(s, a)
    } else {
      //console.log("complete deep", s, v)
      return s
    }
  }
  urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    console.log("base64", base64)
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  calculatePageNumber(offset: number, limitPerPage: number) {
    return Math.floor(offset / limitPerPage) + 1
  }

  calculateTotalPages(totalItems: number, limitPerPage: number) {
    return Math.ceil(totalItems / limitPerPage)
  }

  calculateOffset(pageNumber: number, limitPerPage: number) {
    return pageNumber * limitPerPage - limitPerPage
  }

  convertToLocalDateTime(utcDateTime: string) {
    return new Date(utcDateTime)
  }

  supressNavigationDuplicatedError(error: any) {
    // Ignore the vuex err regarding  navigating to the page they are already on.
    if (error.name !== "NavigationDuplicated" && !error.message.includes("Avoided redundant navigation to current location")) {
      // But print any other errors to the console
      console.log(error)
    }
  }

  showSnackbar(message: string) {
    return this.showSnackbarSuccess(message) // TODO: remove showSnackbar function
  }

  showSnackbarError(message: string) {
    const payload = {
      text: message,
      type: "error",
    }

    eventBus.$emit("display-snackbar", payload)
    return false
  }

  showSnackbarSuccess(message: string) {
    const payload = {
      text: message,
      type: "success",
    }

    eventBus.$emit("display-snackbar", payload)
    return false
  }

  showSnackbarWarning(message: string) {
    const payload = {
      text: message,
      type: "warning",
    }

    eventBus.$emit("display-snackbar", payload)
    return false
  }

  showSnackbarInfo(message: string) {
    const payload = {
      text: message,
      type: "info",
    }

    eventBus.$emit("display-snackbar", payload)
    return false
  }

  formatTimeForTimePicker(date: Date) {
    // padStart is used to make sure the minutes have the length of 2 because getMinutes() may return one digit for minutes less than 10

    let timePart = date.getMinutes().toString()
    timePart = timePart.length === 2 ? timePart : "0" + timePart

    return date.getHours() + ":" + timePart
  }

  formatDateForDatePicker(date: Date) {
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  getSelectedDateTimeInUtc(date: string, time: string) {
    if (time.length < 5) {
      time = "0" + time
    }

    return new Date(
      Number(date.substr(0, 4)),
      Number(date.substr(5, 2)) - 1, // because month in zero indexed in javascript
      Number(date.substr(8, 2)),
      Number(time.substr(0, 2)),
      Number(time.substr(3, 2)),
      0
    ).toISOString()
  }

  getSelectedDateInUtc(date: string) {
    return new Date(
      Number(date.substr(0, 4)),
      Number(date.substr(5, 2)) - 1, // because month in zero indexed in javascript
      Number(date.substr(8, 2)),
      0,
      0,
      0
    ).toISOString()
  }

  uuid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase()
  }

  getDateArray(startDate, endDate, interval: any = "days", format = "YYYY-MM-DD") {
    let dateArray: any = []
    let currentDate = moment(startDate)
    let stopDate = moment(endDate)
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format(format))
      currentDate = moment(currentDate).add(1, interval)
    }
    return dateArray
  }

  getInitials(string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }
  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  // parameter: roles: roles is an array
  formatRolesColumn(roles: any) {
    let modifiedRoles: any = []

    for (let i = 0; i < roles.length; i++) {
      switch (roles[i]) {
        case "DEALER": {
          modifiedRoles.push({name: "Dealer", color: "chipColorRed"})
          break
        }
        case "ADMIN": {
          modifiedRoles.push({name: "Admin", color: "chipColorGreen"})
          break
        }
        case "DEALER-ADMIN": {
          modifiedRoles.push({name: "Dealer Admin", color: "chipColorBlue"})
          break
        }
        case "CIS-FULL": {
          modifiedRoles.push({name: "CIS Full", color: "chipColorPurple"})
          break
        }
        case "CIS-ADMIN": {
          modifiedRoles.push({name: "CIS Admin", color: "chipColorLime"})
          break
        }
        case "CIS-OPS": {
          modifiedRoles.push({name: "CIS Ops", color: "chipColorBrown"})
          break
        }
        case "CIS-User": {
          modifiedRoles.push({name: "CIS User", color: "chipColorPink"})
          break
        }
        case "SUPER": {
          modifiedRoles.push({name: "Super", color: "chipColorYellow"})
          break
        }
        default: {
          modifiedRoles.push({name: roles[i], color: "secondary"})
          break
        }
      }
    }

    return modifiedRoles
  }
}

export const utils = new Utils()
