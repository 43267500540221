var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[(_vm.editor)?_c('div',{staticClass:"editor-tiptap-icons"},[_c('v-btn',{class:{'is-active': _vm.editor.isActive('bold')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-bold")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('italic')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-italic")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('strike')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-strikethrough")])],1),(false)?_c('v-btn',{class:{'is-active': _vm.editor.isActive('code')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleCode()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-code")])],1):_vm._e(),_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .unsetAllMarks()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-clear")])],1),(false)?_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .clearNodes()
          .run()}}},[_vm._v(" clear nodes ")]):_vm._e(),(false)?_c('v-btn',{class:{'is-active': _vm.editor.isActive('paragraph')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setParagraph()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-paragraph")])],1):_vm._e(),_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 1})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 1})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-1")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 2})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 2})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-2")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 3})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 3})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-3")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 4})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 4})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-4")])],1),(false)?_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 5})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 5})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-5")])],1):_vm._e(),(false)?_c('v-btn',{class:{'is-active': _vm.editor.isActive('heading', {level: 6})},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({level: 6})
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-6")])],1):_vm._e(),_c('v-btn',{class:{'is-active': _vm.editor.isActive('bulletList')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-btn',{class:{'is-active': _vm.editor.isActive('orderedList')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1),(false)?_c('v-btn',{class:{'is-active': _vm.editor.isActive('codeBlock')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleCodeBlock()
          .run()}}},[_vm._v(" code block ")]):_vm._e(),_c('v-btn',{class:{'is-active': _vm.editor.isActive('blockquote')},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBlockquote()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-quote-close")])],1),(false)?_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setHorizontalRule()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-quote-close")])],1):_vm._e(),(false)?_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .setHardBreak()
          .run()}}},[_vm._v(" hard break ")]):_vm._e(),_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .undo()
          .run()}}},[_c('v-icon',[_vm._v("mdi-undo")])],1),_c('v-btn',{on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .redo()
          .run()}}},[_c('v-icon',[_vm._v("mdi-redo")])],1)],1):_vm._e(),_c('editor-content',{staticClass:"mt-1",attrs:{"editor":_vm.editor},on:{"input":_vm.changeHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }