import moment from "moment"
import {imageUploadService} from "../image/image-upload"
import {utils} from "../utils"

class AppFilters {
  constructor() {}
  async initialise(Vue) {
    Vue.filter("filter", function (array: any = [], match: any, field: any) {
      let res = array.filter((item) => {
        let fieldArr = field.split(".") //max dept is 4 levels
        if (fieldArr.length == 1) {
          return item[fieldArr[0]] == match
        } else if (fieldArr.length == 2) {
          return item[fieldArr[0]][fieldArr[1]] == match
        } else if (fieldArr.length == 3) {
          return item[fieldArr[0]][fieldArr[1]][fieldArr[2]] == match
        } else if (fieldArr.length == 4) {
          return item[fieldArr[0]][fieldArr[1]][fieldArr[2]][fieldArr[3]] == match
        }
      })
      return res
    })
    Vue.filter("pad02", function (value: any) {
      if (typeof value == "undefined") {
        return ""
      }

      return String(value).padStart(2, "0")
    })
    Vue.filter("serverImage", function (value: any) {
      if (!value) {
        return ""
      }
      if (value.indexOf("http") == 0) {
        return value
      } else {
        return imageUploadService.getImagePath(value)
      }
    })
    Vue.filter("FormatPerc", function (value: any) {
      if (!value) {
        return 0
      }
      return Math.floor((value * 10000) / 100)
    })
    Vue.filter("capitalise", function (value: any) {
      if (!value) {
        return ""
      }

      return value.charAt(0).toUpperCase() + value.slice(1)
    })
    Vue.filter("key2human", function (value: any) {
      if (!value) {
        return ""
      }
      return value.replace(/_/g, " ")
    })
    Vue.filter("pad02", function (value: any) {
      if (typeof value == "undefined") {
        return ""
      }

      return String(value).padStart(2, "0")
    })
    Vue.filter("initials", function (value: any) {
      if (!value) {
        return ""
      }

      return utils.getInitials(value)
    })
    Vue.filter("max", function (text: any, max: any) {
      if (!text || !max) {
        return text
      }

      return text.substr(0, max) + (text.length > max ? "..." : "")
    })
    Vue.filter("para", function (text: any) {
      if (!text) {
        return ""
      }
      return text.replace(/\n/g, "<br/>")
    })
    Vue.filter("html2para", function (text: any) {
      if (!text) {
        return ""
      }
      return text.replace(/<\/?[^>]+>/gi, " ")
    })
    Vue.filter("FormatDateTime", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).format("DD/MM/YYYY HH:mm")
    })
    Vue.filter("FormatDateTimeLong", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).format("dddd, DD MMM, YYYY / h:mma")
    })
    Vue.filter("FormatTime", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value, "HH:mm:ss").format("h:mma")
    })
    Vue.filter("FormatDate", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).format("DD/MM/YYYY")
    })

    Vue.filter("DateTimeShort", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("D MMM, h:mma")
    })
    Vue.filter("DateTimeShortYear", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("D MMM YY, h:mma")
    })
    Vue.filter("DateShortYear", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("D MMM YY")
    })
    Vue.filter("year", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("YYYY")
    })
    Vue.filter("month", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("MMM")
    })
    Vue.filter("day", function (value: any) {
      if (!value) {
        return ""
      }
      return moment(value).format("D")
    })

    Vue.filter("FormatDateLong", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).format("dddd DD MMM, YYYY")
    })
    Vue.filter("FormatDate", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).format("DD/MM/YYYY")
    })

    Vue.filter("fromNow", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).fromNow()
    })

    Vue.filter("toNow", function (value: any) {
      if (!value) {
        return ""
      }

      return moment(value).toNow()
    })

    Vue.filter("FormatMoney", function (value: any) {
      if (!value) {
        return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)
      }

      return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
    })

    Vue.filter("FormatNumber", function (value: any) {
      if (!value) {
        return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)
      }

      return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
    })
    Vue.filter("FormatPerc", function (value: any) {
      if (!value) {
        return 0
      }
      return Math.floor((value * 10000) / 100)
    })

    return true
  }
}

export const appFilters = new AppFilters()
