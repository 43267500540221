<template>
  <div fluid>
    <v-row dense>
      <v-col col="12" md="12">
        <v-card>
          <v-card-title>Account Settings</v-card-title>
          <v-card-text v-if="data.profile">
            User account name: {{ data.profile.username }}
            <div class="my-4" v-if="dashboardUser == 'admin'">
              Access to sites:
              <v-chip v-for="site in data.profile.sites" :key="site.siteID" small class="mr-1 mb-1">
                {{ site.siteID }}
              </v-chip>
            </div>
            <div class="my-4" v-if="data.profile.teams">
              Teams: <br />
              <v-chip dark color="blue-grey lighten-1 mr-2 mb-2" small v-for="item of data.profile.teams" :key="item._id">{{ item.name }}</v-chip>
            </div>
            <v-window v-model="data.step">
              <v-window-item :value="'settings'">
                <h4>Profile</h4>
                <div class="row">
                  <div class="col-12 col-md-12">
                    <v-text-field v-model="data.item.name" autofocus label="Name" :disabled="!data.edit"></v-text-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <v-text-field v-model="data.item.email" autofocus label="Contact Email" :disabled="!data.edit"></v-text-field>
                  </div>
                  <div class="col-12 col-md-6">
                    <v-text-field v-model="data.item.phone" autofocus label="Contact Phone" :disabled="!data.edit"></v-text-field>
                  </div>
                </div>
              </v-window-item>
              <v-window-item :value="'change-password'">
                <h4>Password Update</h4>
                <div class="caption grey--text text--darken-1 mt-4">Please enter a new password for your account</div>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <v-text-field id="password" label="Password" v-model="data.user.password" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>
                  </div>
                  <div class="col-12 col-md-6">
                    <v-text-field id="password" label="Confirm Password" v-model="data.user.confirmPassword" name="confirmPassword" prepend-icon="mdi-lock" type="password"></v-text-field>
                  </div>
                </div>
              </v-window-item>
            </v-window>
          </v-card-text>
          <v-card-actions>
            <template v-if="data.step == 'settings'">
              <v-btn color="primary" class="white--text" @click="changePassword">Change Password</v-btn>
              <v-spacer></v-spacer>
            </template>
            <template v-else>
              <v-spacer></v-spacer>
              <template v-if="data.step == 'change-password'">
                <v-btn color="primary" class="white--text" @click="saveNewPassword">Save New Password</v-btn>
                <v-btn color="secondary" @click="data.step = 'settings'">Back</v-btn>
              </template>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col col="12" md="12" v-if="dashboardUser == 'admin'">
        <v-card>
          <v-card-title>Email Signature</v-card-title>
          <v-card-text>
            <p>Add your email signature here. It will be auto appended to all your Email Replies</p>
            <TipTap v-model="data.preference.emailSignature" />
            <p class="note text-info">Use CTRL + ENTER to break new line</p>
          </v-card-text>
          <v-card-actions>
            <template>
              <v-btn color="primary" class="white--text" @click="saveEmailSignature">Save</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator"
import store from "./../../store"
import {userService} from "./../../libs/user/user-service"
import {preferenceService} from "./preferences-service"
import TipTap from "./../../libs/shared-components/TipTap.vue"
import {utils} from "../../libs/utils"

@Component({
  components: {TipTap},
})
export default class Home extends Vue {
  serviceWorkerRegistration = this.$store.state.serviceWorkerRegistration
  falseee = false
  data: any = {
    step: "settings",
    rules: {},
    profile: {},
    edit: false,
    user: {
      password: "",
      confirmPassword: "",
    },
    preference: {
      emailSignature: "",
    },
    item: {
      email: "",
      phone: "",
      name: "",
    },
  }

  dashboardUser = "admin"

  constructor() {
    super()
  }

  created() {
    this.init()
    this.load()
  }

  edit() {
    this.data.edit = !this.data.edit
  }

  async load() {
    let result: any = {}
    if (this.dashboardUser == "admin") {
      result = await userService.loadProfile()
      if (result) {
        Vue.set(this.data, "profile", result)
        this.data.item = Object.assign({}, result)
      }
    } else {
      if (result && result.data) {
        Vue.set(this.data, "profile", result.data)
        this.data.item = Object.assign({}, result.data)
      }
    }

    if (result && result.error == true) {
      utils.showSnackbar("Error loading profile")
      return
    }

    let pref = await preferenceService.getPreference()
    if (pref) {
      Vue.set(this.data.preference, "emailSignature", pref.emailSignature)
    }
  }
  async changePassword() {
    this.data.step = "change-password"
  }

  async saveEmailSignature() {
    let result = await preferenceService.saveEmailSignature(this.data.preference)
    if (result == true) {
      utils.showSnackbarError("Failed to update email signature")
    } else {
      utils.showSnackbarSuccess("Updated email signature")
    }
  }

  async saveNewPassword() {
    if (!this.data.user.password) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Please enter password and confirm password"})
      utils.showSnackbar("Please enter password and confirm password")
      return
    }
    if (this.data.user.password != this.data.user.confirmPassword) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Password and confirm password do not match"})
      utils.showSnackbar("Password and confirm password do not match")
      return
    }
    let result = await userService.saveNewPassword(this.data.user)
    if (result == true) {
      //EventBus.$emit("display-snackbar", {type: "info", text: "Password updated successfully"})
      utils.showSnackbar("Password updated successfully")
      //this.data.step = "settings"
    }
  }

  async save() {
    if (!this.data.item.email) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Email is required"})
      utils.showSnackbar("Email is required")
      return
    }
    let result = await userService.saveProfile(this.data.item)
    if (result && result.error == true) {
      //EventBus.$emit("display-snackbar", {type: "error", text: (result.message?result.message:"Error saving profile"), subtext: result.message})
      utils.showSnackbar("Error saving profile")
      return
    }
    if (result) {
      this.data.item = Object.assign({}, result)
      this.data.edit = false
    }
  }

  async init() {
    this.data.profile = store.getters.profile
  }
}
</script>
