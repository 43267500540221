import ServerReportApi from "../api-services/server-reports-api"

class SearchService extends ServerReportApi {
  URL_PREFIX = "/v1/secure/search/"

  async find(payload: any) {
    return await this.get(`${this.URL_PREFIX}all`, payload)
  }
}

export const searchService = new SearchService()
